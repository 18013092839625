import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Empty,
  Typography,
  Space,
  Spin,
  Avatar,
  List,
  Tag,
  Alert,
  Result,
  Divider,
  Drawer,
  Dropdown,
  Skeleton,
  Form,
  message
} from "antd";
import {
  DrawerForm,
  ProFormSelect,
  ProFormTextArea,
  ProForm,
} from "@ant-design/pro-components";
import { CheckOutlined, GoogleOutlined } from "@ant-design/icons";

import { Link } from 'react-router-dom'
import Lottie from 'react-lottie';
import CardSend from "../components/CardSend.jsx"
import { MainContext } from "../contexts/main.jsx";

const { Title, Text } = Typography;

const CardWalletComponent = ({currency}) => {  

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectItem, onSelectItem] = useState({currency:'KBS'});
    const { authLiff, isLogin, addressFormat } = useContext(MainContext);

    const [balanceList, setBalanceList] = useState(false);
    const [form] = Form.useForm();


    useEffect(() => {  
      if(isLogin==true){
         init();
      }
    }, [isLogin]);    

    const showDrawer = (item) => {
      onSelectItem(item)
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    const init = async() => {
      await onLoadBalance()
    } 

    const onLoadBalance = async() => {
        setLoading(true)
        const accessToken = await authLiff.getAccessToken()
        fetch("/api/liff/kuber/wallet/list", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            .then((response) => response.json())
            .then((data) => {
                setBalanceList(data.data)
                setLoading(false)
                console.log('Success:', data);
            })
            .catch((error) => {
                setLoading(false)
                console.error('Error:', error);
            });
    } 

    const RenderWallet = () => {
      return (
        <Card className="mb-2">
          <Row gutter={[12, 12]}>
            {loading? (
              <Skeleton avatar paragraph={{ rows: 1 }} />
            ) : (balanceList.length >=1? (
              <Col span={24}>

              <List
                dataSource={balanceList}
                renderItem={(item) => (
                  <List.Item key={item._id} onClick={()=>showDrawer(item)}>
                    <List.Item.Meta
                      avatar={ <Avatar src={`/img/token/${item.currency}.png`} />}
                      title={item.name}
                      description={addressFormat(item.address)}
                    />
                    <Text strong className={'pl-10'}>{item.balance} {item.currency}</Text>
                  </List.Item>
                )}
              />


              </Col>
          ) : (
            <Col span={24}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Col>
          ))}          
          </Row>
        </Card>)
    }

    return (
      <>
      <RenderWallet />
        {open && <Drawer
          placement={'bottom'}
          title={'Send Token'}
          closable={true}
          onClose={onClose}
          height={'80%'}
          open={open}
        >
          <CardSend currency={selectItem.currency} />
        </Drawer>}
      </>
    ); 
};

export default CardWalletComponent;

