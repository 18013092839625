import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Empty,
  Typography,
  Space,
  Spin,
  Avatar,
  Tag,
  Alert,
  Tooltip,
  Result,
  Skeleton,
  List,
  Divider,
  Image,
  Drawer,
  Dropdown,
  message
} from "antd";

import { CheckOutlined, GoogleOutlined, ForwardOutlined } from "@ant-design/icons";
import {
  ProDescriptions,
} from "@ant-design/pro-components";
import moment from 'moment'
import { Link, useSearchParams } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../contexts/main.jsx";

const { Title, Text, Paragraph } = Typography;

const CardTransactionComponent = ({currency}) => {  
    const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = useState(false);
    const [record, setRecord] = useState(false);
    const [loading, setLoading] = useState(false);
    const [transactionList,setTransactionList] = useState([])

    const active_tx = searchParams.get('tx') || '-';

    const { authLiff, isLogin, addressFormat, getCoreValue, sleep } = useContext(MainContext);

    useEffect(() => {  
      if(isLogin==true){
         init();
      }
    }, [isLogin]);    

    useEffect(() => {  
      if(isLogin==true){

        if(active_tx!=='-'){
          var found = transactionList.filter(item=>item._id==active_tx)
          if(found && found.length>=1){
            showDrawer(found[0])
          }
        }


      }
    }, [transactionList]);       

    const init = async() => {
      await onLoadTransaction()
    } 

    const showDrawer = (data) => {
      setRecord(data)
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    const onLoadTransaction = async() => {
        setLoading(true)
        const accessToken = await authLiff.getAccessToken()
        fetch("/api/liff/kuber/list/history?token="+currency, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            .then((response) => response.json())
            .then(async(data) => {
                setTransactionList(data.data)
                setLoading(false)
                console.log('Success:', data);
            })
            .catch((error) => {
                setLoading(false)
                console.error('Error:', error);
            });
    } 

    const tagColor={
      AIR_DROP:'processing',
      SEND:'success',
      CLAIM:'gold',
      UNSTAKE:'cyan',
      DAILY_CHECK_IN:'purple'
    }

    const RenderInfo = ({record}) => {
      var detail = [
        {
          title: 'Type',
          render: () => (
            <Tag bordered={false}>{record.type}</Tag>
          ),
        },
        {
          title: () => 'Amount',
          render: () => (
            <Space>
              <Avatar src={`/img/token/${record.symbol}.png`} />
              <Text>{record.amount} {record.symbol}</Text>
            </Space>
          ),
        },
        {
          title: 'From',
          render: () => (
            <Space>
              <Avatar src={record.from_avatar} />
              <Text strong>{record.from_name.split('@')[0]}</Text>
            </Space>
          ),
        },
        {
          title: 'To',
          render: () => (
            <Space>
              <Avatar src={record.to_avatar} />
              <Text strong>{record.to_name.split('@')[0]}</Text>
            </Space>
          ),
        },
      ]

      if(record.comment && record.comment!=''){
        detail.push({
          title: 'Comment',
          span: 2,
          render: () => (
            <Card style={{width:'100%'}}>
              <Paragraph style={{ width:'100%', margin: 0 }}>
                {record.comment}
              </Paragraph>
            </Card>
          ),
        })
      }
      if(record.core_value && record.core_value!=0){
        detail.push({
          title: 'Core Value',
          span: 2,
          render: () => (
            <Space>
              <Avatar src={`/img/core_value/${record.core_value}.svg`} />
              <Text>{getCoreValue(record.core_value - 1)}</Text>
            </Space>
          )
        })
      }
      if(record.point && record.point!=0){
        detail.push({
          title: 'Point',
          span: 2,
          render: () => (
            <Space>
                <Avatar src={record.to_avatar} />
                <Text strong>{`+${record.point} points`}</Text>
            </Space>
          ),
        })
      }
      if(record.hash && record.hash!=''){
        detail.push({
          title: 'Hash',
          span: 2,
          render: () => (
            <Link underline to={`https://testnet.bkcscan.com/tx/${record.hash}`} target="_blank">
              {addressFormat(record.hash)}
            </Link>
          ),
        })
      }

      return (<ProDescriptions
        column={2}
        size="small"
        layout="vertical"
        columns={detail}
      />)
    }



    const RenderTransaction = () => {
      return (
        <Card className="mb-2">
          <Row gutter={[12, 12]}>
            <Col span={24} align="left">
              <Title level={3} className="mr-5">Transaction ({currency})</Title>
            </Col>
            {loading? (
                <Skeleton avatar paragraph={{ rows: 1 }} />
            ) : (transactionList.length >=1? (
                <Col span={24}>

                <List
                  dataSource={transactionList}
                  renderItem={(item) => (
                    <List.Item key={item._id} onClick={()=>showDrawer(item)}>
                      <List.Item.Meta
                        avatar={ <Avatar.Group><Tooltip title={item.from_name.split("@")[0]}><Avatar src={item.from_avatar} /></Tooltip> <ForwardOutlined /> <Tooltip title={item.to_name.split("@")[0]}><Avatar src={item.to_avatar} /></Tooltip></Avatar.Group>}
                        title={addressFormat(item.hash)}
                        description={(<Text type="secondary">{item.comment}<br /><Tag bordered={false}><small style={{textAlign:'left'}}>{moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss')}</small></Tag></Text>)}
                      />
                      <Space align="end" direction={'vertical'}>
                        <Text strong style={{textAlign:'right'}}>{item.amount} {item.symbol}</Text>
                      </Space>

                    </List.Item>
                  )}
                />


                </Col>
              ) : (
                <Col span={24}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Col>
              ))}
          </Row>
          {open && <Drawer
            placement={'bottom'}
            closable={true}
            title={'Transaction info'}
            onClose={onClose}
            height={'80%'}
            destroyOnClose={true}
            open={open}
          >
            <RenderInfo record={record} />
          </Drawer>}
        </Card>
      )
    }

    return (
      <RenderTransaction />
    ); 
};

export default CardTransactionComponent;

