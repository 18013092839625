import React, { useEffect, useRef, useState, useContext } from "react"; 
import {
  Row,
  Col,
  Button,
  Divider,
  Typography,
  Result,
  Dropdown,
  Space,
  QRCode,
} from "antd";
import { Link, useParams } from 'react-router-dom'
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
const { Title, Text } = Typography;

const Page = () => {

    const [ qrData, setQrData ] = useState(false)
    const { authLiff, isLogin, sleep } = useContext(MainContext);


    useEffect(() => {  
      if(isLogin==true){
         onQr()
      }
    }, [isLogin]);  

    const onQr = async() => {
      try {
          const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/event/hodl_day/qr/gen`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
                  if(data.status==true){
                      setQrData(data)
                  }
              }).catch((error) => {});
      } catch(error) {
      }
    }

	const RenderQr = ({data}) => {
	  return (
	        <Result
	          icon={data?.data && (<div style={{padding:0,display: 'flex',justifyContent: 'center',alignItems: 'center',textAlign: 'center'}}>
	            <QRCode
	              errorLevel="H"
	              bgColor="#ffffff"
	              bordered={true}
	              size={250}
	              value={data?.data}
	              icon={`/img/liff/event/hodl_day/${data?.info?.dao}.png`}
	            />
	          </div>)}
	          title={(<Title style={{color:'#ffffff'}}>QR Code</Title>)}
	          subTitle={(
				<Space style={{textAlign:'center',marginTop:30}} direction={'vertical'}>
					<Text style={{fontSize:20,color:'#ffffff'}}>{data?.info?.name}<br />Team {data?.info?.dao}<br />{data?.info?.employee_id}</Text>
				</Space>
	          )}
	          style={{padding:0}}
	        />
	  )
	}

  return (
  	<LinkAccount liffId={'1657440707-oJ18Pv8l'}>
	  	<div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/hodl_day/bg.png)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
		  	<Row gutter={[0, 0]} className="mb-10">
	          <Col span={24} style={{textAlign:'center'}}>
	          	<img src={"/img/liff/event/hodl_day/logo.png"} style={{margin:'auto',height:250}}/>
	          </Col>
	          <Col span={24}>
	            {qrData && <RenderQr data={qrData}/>}
	          </Col>
	        </Row>
	    </div>
    </LinkAccount>
  );
};


export default Page;