import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Divider,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Checkbox,
  List,
  Spin,
  Avatar,
  Radio,
  Alert,
  Result,
  Dropdown,
  Modal,
  QRCode,
  message,
  Segmented
} from "antd";

import { ExclamationCircleOutlined, CheckOutlined, CheckCircleOutlined, GoogleOutlined, LinkOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom'
import Lottie from 'react-lottie';
import FormSelect from "./FormSelect.jsx"
import CardView from "./CardView.jsx"
import { MainContext } from "../contexts/main.jsx";
import * as animationData from '../assets/lottie/done.json'

const { Title, Text } = Typography;
const example = [
  {
    example_core: [
      "รักษาความลับขององค์กรอย่างสุดความสามารถ",
      "สื่อสารอย่างเปิดเผยและตรงไปตรงมาให้ทุกคนรับรู้กันอย่างทั่วถึง",
    ],
    example_msg: [
      "ขอบคุณน้อง Bullkub ที่เก็บรักษาความลับของทีมเป็นอย่างดีค่ะ!",
      "Thank you to P’Rabitian ให้ feedback น้องๆอย่างตรงไปตรงมา เอามงไปเลย!",
    ],
  },
  {
    example_core: [
      "ยึดภารกิจของบิทคับเป็นที่ตั้ง และคำนึงถึงในทุก ๆ การตัดสินใจ",
      "สรรหา และนำเสนอวิธีการใหม่ ๆ ที่ช่วยส่งเสริมภารกิจของบิทคับ",
    ],
    example_msg: [
      "ขอบคุณพี่แรบบิทสำหรับกิจกรรมเสริมทักษะ ใน Team Meeting ตลอดเล้ยยยย",
      "ขอบคุณพี่แรบบิทที่เสนอการแก้ไขปัญหาใหม่ๆ ได้อย่าง เยี่ยมจริงๆๆ!",
    ],
  },
  {
    example_core: [
      "ออกความเห็นอย่างตรงไปตรงมาเพื่อผลลัพธ์ที่ดีที่สุดของทีม",
      "ตัดสินใจร่วมกันในฐานะทีม และลุยไปด้วยกันจนสุดทาง",
    ],
    example_msg: [
      "ขอบคุณน้องบุญคับที่ออกความเห็นอย่างตรงไปตรงมาให้กับทีมของเราค่าาา",
      "พี่แรบบิท Challenger ของเรา ลุยกับทีมไปด้วยกันในงาน Bitkub Career!",
    ],
  },
  {
    example_core: [
      "ลำดับความสำคัญของงานอย่างชัดเจน และส่งมอบงานอย่างรวดเร็ว บนมาตรฐานที่สูงที่สุด",
      "มองหาวิธีการ และเครื่องมือใหม่ ๆ มาช่วยพัฒนาประสิทธิภาพงานเสมอ",
    ],
    example_msg: [
      "ขอบคุณน้องบุญคับสำหรับ Employee Report ที่รวดเร็วทันใจ :)",
      "ขอบคุณพี่แรบบิทสำหรับ Tools ใหม่ๆที่ช่วยพัฒนา Kuber อย่างต่อเนื่องค่าาา",
    ],
  },
  {
    example_core: [
      "รักษาคำพูดและทำสุดความสามารถเพื่อส่งมอบงานตามที่ตกลง",
      "ลงมือแก้ไขปัญหาทันทีที่พบเจอแม้จะนอกเหนือความรับผิดชอบของตัวเอง",
    ],
    example_msg: [
      "พี่แรบบิทคือ Project Owner ที่สุดปัง ทำให้ Bitkub Career Fanpage โด่งดังสุดๆ",
      "ขอบคุณน้องบุญคับที่แก้ไขปัญหาให้ลูกค้าได้อย่างรวดเร็ววว",
    ],
  },
  {
    example_core: [
      "มองหาเทรนด์ และเทคโนโลยีใหม่ ๆ เพื่อสร้างโอกาสให้กับองค์กรตลอดเวลา",
      "ใช้ทุก ๆ สถานการณ์เป็นโอกาสในการเรียนรู้ และพัฒนาตัวเอง",
    ],
    example_msg: [
      "พี่แรบบิท คือผู้นำเทรนด์ใหม่ๆมาปรับใช้กับทีมของเราตลอดเลยยย",
      "พี่แรบบิท ผู้เป็น Top Learner ประจำทีม ไอดอลเลยค่าาาา",
    ],
  },
  {
    example_core: [
      "รับฟังอย่างตั้งใจ และเข้าใจถึงความคิดและความรู้สึกของผู้อื่น",
      "แสดงความชื่นชมยินดีต่อความพยายามของผู้อื่น รวมถึงรู้ว่าเมื่อใดควรยืนหยัดในความคิด หรือเมื่อใดควรประนีประนอมเมื่อต้องทำงานร่วมกันเพื่อเป้าหมายของกันและกัน",
    ],
    example_msg: [
      "พี่แรบบิทคือ The Best Supporter รับฟังน้องๆตลอดเลย",
      "ได้กำลังใจจากพี่แรบบิทใน Mental Clinic มาเยอะมากๆเลยค่าาา",
    ],
  },
];
const CardSendComponent = ({currency}) => {  
    const [form] = Form.useForm();
    const [ loading, setLoading ] = useState(false);
    const [ step, setStep ] = useState(0);
    const [ formData, setFormData ] = useState(false);
    const [selected, setSelected] = useState();

    const [ modal, contextHolder ] = Modal.useModal();
    const { authLiff, sleep } = useContext(MainContext);


    const onFinish = async(data) => {
      const confirmed = await modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        destroyOnClose:true,
        content: `send ${data.amount} ${currency} to ${data.employee_id.label}? `,
        okText: 'Confirm',
        cancelText: 'Cancel',
      });
      if(confirmed){
        setStep(1)
        setLoading(true)
        const accessToken = await authLiff.getAccessToken()
        fetch(`/api/liff/kuber/wallet/send`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                  to:data.employee_id.value,
                  amount:data.amount,
                  core_value:data.core_value,
                  comment:data.comment
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                setLoading(false)
                if(data.status==true){

// 6|event.web_backend  |   status: true,
// 6|event.web_backend  |   data: {
// 6|event.web_backend  |     _id: new ObjectId("6549ccc6235f3a6e9a46f491"),
// 6|event.web_backend  |     hash: '0xea9848aa9d7a4d3a7354787720d0d0da58c499943ed25898b979a3f2920acc26',
// 6|event.web_backend  |     from: {
// 6|event.web_backend  |       email: 'krit.chakkrit@bitkub.com',
// 6|event.web_backend  |       avatar: 'https://lh3.googleusercontent.com/a-/ALV-UjWhEYfn7r_gT8afoUG_lBcc7HzjqMFVON8Nt7gt6K0MkDU=s96-c',
// 6|event.web_backend  |       wallet: '0xf9dda8a3edcd446a4420a6bf69c426ca2a0d284a'
// 6|event.web_backend  |     },
// 6|event.web_backend  |     to: {
// 6|event.web_backend  |       email: 'pop.warunchaya.i@bitkub.com',
// 6|event.web_backend  |       avatar: 'https://lh3.googleusercontent.com/a-/ALV-UjU12ty9IMXqx8my6UO8C_5b-Xs2TF7Ef883j4MhWGgvbQ=s96-c',
// 6|event.web_backend  |       wallet: '0xa308d181a8a7a884c06aad345420571fabb46fba'
// 6|event.web_backend  |     },
// 6|event.web_backend  |     amount: 5,
// 6|event.web_backend  |     core_value: '7 (Empathy and Collaboration)',
// 6|event.web_backend  |     comment: 'ขอบคุณสำหรับการช่วยเหลือในหลายๆด้าน'
// 6|event.web_backend  |   }



                }else{
                  message.info(data.message);
                  setStep(2)
                }
            })
            .catch((error) => {
                setStep(2)
                setLoading(false)
            });
      }
    }

    const fetchData = async(email) => {
        const accessToken = await authLiff.getAccessToken()
        return fetch('/api/liff/kuber/wallet/search/'+email, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              }
          })
          .then((res) => res.json())
          .then((body) =>body.data.map((user) => ({
                label: user.company_email,
                value: user._id,
              }),
          ))
    }

    const RenderFormSend = () => {
      return (
        <>
        <Form form={form} onFinish={onFinish} layout="vertical" autoComplete="off">
          <Form.Item key={'employee_id'} name={'employee_id'} rules={[{ required: true}]}>
            <FormSelect
              showSearch
              allowClear
              placeholder="Select user from employee email"
              fetchOptions={fetchData}
              style={{ width: '100%'}}
            />
          </Form.Item>
          <Form.Item key={'amount'} name={'amount'} label={'Amount (KBS)'} >
            <Select
              placeholder="Select amount"
              options={[
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4", value: 4 },
                { label: "5", value: 5 },
              ]}
              optionItemRender={(item)=>{
                return (
                  <Space size={4}>
                    <Avatar src="/img/token/KBS.png" size={20} />
                    <Text>
                      <small>{`${item.label} KBS`}</small>
                    </Text>
                  </Space>
                );
              }}
              rules={[{ required: true, message: "" }]}
            />
          </Form.Item>
          <Form.Item key={'core_value'} name={'core_value'} label={'Choose core value'} >
            <Select
              placeholder="Select core value"
              options={[
                { label: "1.Earn Trust", value: 1 },
                { label: "2.Passionate and Dive Deep", value: 2 },
                { label: "3.Challenge and Commit", value: 3 },
                { label: "4.Strive for results and Actions", value: 4 },
                { label: "5.Ownership and Beyond", value: 5 },
                { label: "6.Adapt and Innovate", value: 6 },
                { label: "7.Empathy and Collaboration", value: 7 },
              ]}
              onChange={(value) => setSelected(value)}
              optionItemRender={(item)=>{
                return (
                    <Space size={4}>
                      <Avatar src={`/img/core_value/${item.value}.svg`} size={20} />
                      <Text>
                        <small>{`${item.label}`}</small>
                      </Text>
                    </Space>
                );
              }}
              rules={[{ required: true, message: "" }]}
            />
          </Form.Item>

          {selected && (
            <Form.Item>
              <Alert
                style={{ padding: "2px 10px" }}
                description={
                  <Space direction="vertical" size={2}>
                    <Text strong>
                      <small>ตัวอย่างพฤติกรรมพึงประสงค์</small>
                    </Text>
                    <Space direction="vertical" size={0}>
                      {example[selected - 1].example_core.map((item, index) => (
                        <Text key={index} type="secondary">
                          <small>{`${index + 1}. ${item}`}</small>
                        </Text>
                      ))}
                    </Space>
                    <Text strong>
                      <small>ตัวอย่างข้อความ</small>
                    </Text>
                    <Space direction="vertical" size={0}>
                      {example[selected - 1].example_msg.map((item, index) => (
                        <Text key={index} type="secondary">
                          <small>{`${index + 1}. ${item}`}</small>
                        </Text>
                      ))}
                    </Space>
                  </Space>
                }
                type="success"
              />
            </Form.Item>
          )}


          <Form.Item key={'comment'} name={'comment'} label={'Details'}  rules={[{ required: true }]} >
            <Input.TextArea
              showCount
              maxLength={300}
              style={{ height: 120 }}
              placeholder="Details"
              showCount={{
                formatter: (info) => (
                  <Text type="secondary">{`max length : ${info.maxLength - info.count}`}</Text>
                ),
              }}
              maxLength={150}
            />
          </Form.Item>
          <Divider />
          <Form.Item>
              <Button type="primary" size="large" htmlType="submit" icon={<CheckCircleOutlined />} block>Send</Button>
          </Form.Item>
        </Form>
        {contextHolder}
        </>
      )
    }

    const onClear= () =>{
      setStep(0)
    }

    return currency=='KBS'? (
        <>
          <Row gutter={[12, 12]}>
              <Col span={24} align="left">
                  {(loading==false && step==0) && <RenderFormSend/>}
                  {(loading==true && step==1) && <CardView view='waiting'/>}
                  {(loading==false && step==1) &&<CardView view='success' onClear={()=>onClear()}/>}
                  {(loading==false && step==2) &&<CardView view='error' onClear={()=>onClear()}/>}
              </Col>
          </Row>
        </>
    ) : (<>
          <Row gutter={[12, 12]}>
              <Col span={24} align="left">
                <Alert
                  message="Coming Soon"
                  description="เตรียมพบกันเร็วๆนี้"
                  type="info"
                  showIcon
                />
              </Col>
          </Row>
    </>); 
};

export default CardSendComponent;

