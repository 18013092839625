import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Drawer,
  InputNumber,
  Checkbox,
  Layout
} from "antd";
import QrReader from "react-qr-reader";
import { CheckOutlined, GoogleOutlined } from "@ant-design/icons";
import { Link, useSearchParams, useNavigate } from 'react-router-dom'

const { Title, Text } = Typography;
const Page = () => {
  const [data, setData] = useState('No result');
  const camError = (error) => {
    if (error) {
      console.info(error);
    }
  };
  
  const menuList = [
    {name:'Bitkub Mate',url:'/tv/bitkub_mate'}
  ] 
  return (
    <>

          <div style={{margin: 0,display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
            <Card style={{maxWidth: '450px',marginTop: '100px',width: '90%'}} className="Wrap">
                <img src="/logo_kuber.png" style={{'height': 39,marginLeft:-10,marginBottom:20}}/>
                <div style={{width:410,height:410,border:'5px solid #cccc',borderRadius: 20,padding:20,overflow:'hidden'}}>
                {menuList.map(item=>{
                  return (
                    <Link to={item.url}>
                      <Button type="primary" block size="large">
                        {item.name}
                      </Button>
                    </Link>
                  )
                })}
                </div>
                <small style={{marginTop:20,display:'block'}}>BITKUB CAPITAL GROUP HOLDINGS CO., LTD.</small>
            </Card>
          </div>

    </>
  );
};

export default Page;
