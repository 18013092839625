import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Checkbox,
  List,
  Spin,
  Avatar,
  Radio,
  Tag,
  Alert,
  Result,
  Divider,
  Drawer,
  Dropdown,
  message
} from "antd";

import { CheckOutlined, GoogleOutlined, ProfileTwoTone, TagsTwoTone } from "@ant-design/icons";

import { Link } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../../../../contexts/main.jsx";

import LinkAccount from '../../../../components/LinkAccount.jsx'
import CardTicket from '../../../../components/CardTicket.jsx'

const { Title, Text } = Typography;
const PageLiffKuberTicket = () => {  

    const [loading, setLoading] = useState(false);
    const { authLiff, authData, isLogin, addressFormat } = useContext(MainContext);

    return (
        <LinkAccount liffId={'1657440707-3VbYm4Yk'}>
              <CardTicket/>
        </LinkAccount>
    ); 
};

export default PageLiffKuberTicket;

