import React, { useEffect, useRef, useState, useContext } from "react"; 
import {
  Row,
  Col,
  Card,
  Button,
  Empty,
  Typography,
  Space,
  Spin,
  Avatar,
  Alert,
  Image,
  Result,
  message
} from "antd";

import { CheckOutlined, GoogleOutlined, LinkOutlined } from "@ant-design/icons";
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import Lottie from 'react-lottie';
import CardPay from "./CardPay.jsx"
import { MainContext } from "../contexts/main.jsx";

import * as animationData from '../assets/lottie/done.json'
import * as animationError from '../assets/lottie/error.json'
import * as animationScand from '../assets/lottie/scand.json'
import * as animationWait from '../assets/lottie/block_confirm.json'

const { Title, Text, Paragraph } = Typography;

const CardScanComponent = () => {  

    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get('code') || '-';
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dataPay, setDataPay] = useState(false);
    const [dataForm, setDataForm] = useState(false);
    const { authLiff, isLogin, setActivePay, addressFormat, sleep } = useContext(MainContext);


    useEffect(() => {  
      if(isLogin==true && code.length>=10){
         queryCode(code);
      }
    }, [isLogin]);   



    useEffect(() => {  
      if(step==2){
         onRecheck();
      }
    }, [step]);  

    const onRecheck = async() =>{
      if(authLiff.getContext().type!=='none'){
        authLiff
          .sendMessages([
            {
              type: "text",
              text: "recheck",
            },
          ])
          .then(() => {
            message.info('success');
          })
          .catch((err) => {
            console.log("error", err);
          });
      }
    }


    const queryCode = async(value) => {
      const qr_code = value.split('?code=').length==2? value.split('?code=')[1] : value
      setActivePay(true)
      setDataPay(qr_code)
      const accessToken = await authLiff.getAccessToken()
      fetch('/api/liff/kuber/qr/scand/check', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
              body: JSON.stringify({
                code:qr_code,
                sv:authLiff.getContext().type
              }),
          })
          .then((response) => response.json())
          .then((data) => {
              setLoading(false)
              if(data.status==true){
                if(data.url){
                   navigate(data.url);
                }else{
                  setDataForm(data)
                  setStep(1)
                }
              }else{
                setStep(3)
                setDataForm(data)
                message.info(data.message);
              }
          })
          .catch((error) => {
              setActivePay(false)
              setLoading(false)
          });
    }

    const onScanAction = async() => {
      try {
          setLoading(true)
          authLiff
            .scanCodeV2()
            .then(async(result) => {
                  if(result && result.value){
                    queryCode(result.value)
                  }else{
                    setLoading(false)
                  }
            }).catch((error) => {
                setLoading(false)
                message.info('line app not support camera');
            });
        
      } catch(error) {
        setLoading(false)
      }
    }

    const RenderScand = () => {
        const exrea = [
          <Button type="primary" block size="large" onClick={onScanAction} loading={loading} disabled={loading}>
            Scan Qr
          </Button>
        ]
        return (
          <>
            <Result
              icon={<Lottie options={{
                  animationData: animationScand,
                  loop: false,
                  autoplay: true,
                }} height={200} width={200}/>}
              title={'Scan QR'}
              subTitle={"กรุณาทำการกดที่ปุ่ม "+code}
              style={{padding:0}}
              extra={exrea}
            />
          </>
        )
    }

    const RenderScandWaiting = ({dataForm}) => {

        return (
            <Result
              icon={<Lottie options={{
                  animationData: animationWait,
                  loop: true,
                  autoplay: true,
                }} height={200} width={200}/>}
              title="Waiting"
              subTitle="กำลังรอ confirm ธุรกรรม"
              style={{padding:0}}
            />
        )
    }

    const RenderIcon = ({icon})=>{
      if(['token'].includes(icon.type)){
        return <Avatar src={icon.image} style={{width:100,height:100}}/>
      }else if(['gift','card'].includes(icon.type)){
        return <img src={icon.image} style={{width:200}}/> 
      }else if(['error'].includes(icon.type)){
        return (
          <Lottie options={{
              animationData: animationError,
              loop: false,
              autoplay: true,
            }} height={120} width={120}/>
        )
      }else{
        return (
          <Lottie options={{
              animationData: animationData,
              loop: false,
              autoplay: true,
            }} height={200} width={200}/>
        )
      }
    }

    const RenderScandDone = ({dataForm}) => {
        return (
            <Result
              title={dataForm.message}
              icon={<RenderIcon icon={{type:'error'}}/>}
              extra={[
                <Button type="primary" block size="large" onClick={()=>{
                    setActivePay(false);
                    setStep(0);
                    setLoading(false);
                    setDataPay(false);
                    setDataForm(false);
                }}>
                  Close
                </Button>
              ]}
              style={{padding:0}}
            >
            </Result>
        )
    }
    const RenderScandSuccess = ({dataForm}) => {
        var info = dataForm.info
        var res = dataForm.res
        var icon = dataForm.info.icon || false
        return (
            <Result
              title={info.title}
              subTitle={info.subTitle}
              icon={<RenderIcon icon={icon}/>}
              extra={[
                <Button type="primary" block size="large" onClick={()=>{
                    setActivePay(false);
                    setStep(0);
                    setLoading(false);
                    setDataPay(false);
                    setDataForm(false);
                    if(code!='-'){
                      authLiff.closeWindow();
                    }
                }}>
                  Close
                </Button>
              ]}
              style={{padding:0}}
            >
              {res && <div className="desc" style={{padding:0}}>
                <Paragraph>
                  <Text
                    strong
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Transaction info:
                  </Text>
                </Paragraph>
                <Paragraph>
                  {res.hash && (<><Text>Hash : <Text copyable={{ text: res.hash }}>{addressFormat(res.hash)}</Text></Text><br /></>)}
                  {res.from_address && (<><Text>From : <Text copyable={{ text: res.from_address }}>{addressFormat(res.from_address)}</Text></Text><br /></>)}
                  {res.to_address && (<><Text>To : <Text copyable={{ text: res.to_address }}>{addressFormat(res.to_address)}</Text></Text><br /></>)}
                  {res.amount && (<><Text>Amount : <Text>{res.amount}</Text></Text><br /></>)}
                  {res.comment && (<><Text>Comment : <Text>{res.comment}</Text></Text><br /></>)}
                  {res.gas_use && (<><Text>Gas Use : <Text>{res.gas_use}</Text></Text><br /></>)}
                  <Text>Status : <Text>{res.status.toString()}</Text></Text>
                </Paragraph>
              </div>}
            </Result>
        )
    }

    const onSubmit = async(value) => {
      setLoading(true)
      const accessToken = await authLiff.getAccessToken()
      fetch('/api/liff/kuber/qr/scand/submit', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
              amount:value.amount,
              code:dataPay,
              sv:authLiff.getContext().type
            }),
        })
        .then((response) => response.json())
        .then((data) => {
            setLoading(false)
            if(data.status==true){
              setDataForm(data)
              //send message to chat
              setStep(2)
            }else{
              message.info(data.message);
            }
        })
        .catch((error) => {
            setLoading(false)
        });
    }

    const onClear = () =>{
      setStep(0);
      setDataForm(false);
    }

    return (
        <>
          {step==0 && code=='-' && <RenderScand/>}
          {step==1 && loading==false && dataForm && <CardPay dataForm={dataForm} onClear={onClear} onSubmit={onSubmit}/>}
          {step==1 && loading==true && <RenderScandWaiting dataForm={dataForm}/>}
          {step==2 && loading==false && <RenderScandSuccess dataForm={dataForm}/>}
          {step==3 && loading==false && <RenderScandDone dataForm={dataForm}/>}
        </>
    ); 
};

export default CardScanComponent;

