import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Checkbox,
  List,
  Spin,
  Avatar,
  Radio,
  Alert,
  Result,
  Dropdown,
  Segmented,
  message,
} from "antd";

import { CheckOutlined, GoogleOutlined, LinkOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom'
import { QRCode } from 'react-qrcode-logo';
import Lottie from 'react-lottie';
import liff from '@line/liff';
import { MainContext } from "../../../../contexts/main.jsx";
import LinkAccount from '../../../../components/LinkAccount.jsx'
import CardQr from '../../../../components/CardQr.jsx'
import CardScan from '../../../../components/CardScan.jsx'
import CardPay from '../../../../components/CardPay.jsx'
const { Title, Text } = Typography;

const PageLiffKuberQr = () => { 

    const [ viewPay, setViewPay ] = useState('pay')
    const [ viewValue, setViewValue ] = useState('scan')
    const [ formData, setFormData ] = useState(false)

    const { authLiff, activePay, sleep } = useContext(MainContext);

    // <Card className="mb-5" size={'small'}>
    //       {activePay==false && <Segmented options={[{ label: 'Scan Qr', value: 'scan'}, { label: 'Show Qr', value: 'qr'}]} value={viewValue} onChange={setViewValue} />}
    //       {viewValue=='qr' && <CardQr/>}
    //       {viewValue=='scan' && <CardScan/>}
    // </Card>

    return (
        <LinkAccount liffId={'1657440707-VaajzPjX'}>
            <Card className="mb-5" size={'small'}>
                <CardScan/>
                {/*<Result
                  title="Expired"
                  subTitle="หมดระยะเวลากิจกรรม"
                />*/}
            </Card>
        </LinkAccount>
    ); 
};

export default PageLiffKuberQr;

