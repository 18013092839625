import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Checkbox,
  List,
  Spin,
  Avatar,
  Radio,
  Tag,
  Alert,
  Skeleton,
  FloatButton,
  Carousel,
  Result,
  Divider,
  Drawer,
  Dropdown,
  Upload,
  Rate,
  Image,
  Modal,
  message
} from "antd";

import { UploadOutlined, ExclamationCircleOutlined, CheckCircleOutlined, CommentOutlined, BookTwoTone, FlagTwoTone, ArrowLeftOutlined, FrownOutlined, MehOutlined, SmileOutlined, CheckOutlined, SendOutlined, GoogleOutlined, ProfileTwoTone, TagsTwoTone, TrophyTwoTone, WalletTwoTone } from "@ant-design/icons";
import { Link, useParams } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../../../../contexts/main.jsx";
import LinkAccount from '../../../../components/LinkAccount.jsx'
const customIcons = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};

const { Title, Text } = Typography;
const PageLiffKuberWallet = () => {  
    let { id } = useParams();
    const [form] = Form.useForm();
    const [ loading, setLoading ] = useState(false);
    const [ waiting, setWaiting ] = useState(false);
    const [ agree, setAgree ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ menuTab, setMenuTab ] = useState('faq');
    const [ modal, contextHolder ] = Modal.useModal();
    const { authLiff, authData, isLogin, addressFormat } = useContext(MainContext);

    useEffect(() => {  
      if(isLogin==true){
         setLoading(false)
      }
    }, [isLogin]);  
    
    const helpList = [

      {name:"ปัญหาที่พบ",type:"form",list:[
        {name:"สแกนจ่ายเงินไม่ได้",detail:[
          {element:"text",detail:"สแกนจ่ายเงินไม่ได้..."}
        ]},
        {name:"สแกนจ่ายเงินเรียบร้อยแล้วแต่เหรียญ ในระบบไม่หัก",detail:[
          {element:"text",detail:"สแกนจ่ายเงินเรียบร้อยแล้วแต่เหรียญ ในระบบไม่หัก..."}
        ]},
        {name:"สแกนรับเหรียญแต่เหรียญไม่เข้าระบบ",detail:[
          {element:"text",detail:"สแกนรับเหรียญแต่เหรียญไม่เข้าระบบ..."}
        ]},
        {name:"กรอกจำนวนเงินในการชำระเงินผิด",detail:[
          {element:"text",detail:"กรอกจำนวนเงินในการชำระเงินผิด..."}
        ]}
      ]},
      {name:"วิธีการใช้งาน",type:"content",list:[

        {name:"วิธีเช็คยอดเงินคงเหลือ / ประวัติการใช้งาน",detail:[
          {element:"title",size:3,detail:"เช็คยอดเงินคงเหลือ/ประวัติใช้งาน"},
          {element:"text",detail:"กดที่รูป wallet เพื่อดูยอดเงินคงเหลือของ line menu"},
          {element:"img",size:250,detail:"/img/faq/wallet.png",url:"https://liff.line.me/1657440707-57v1Ao19"}
        ]},
        {name:"วิธีการใช้เหรียญซื้อสินค้า",detail:[
          {element:"title",size:3,detail:"วิธีการใช้เหรียญซื้อสินค้า"},
          {element:"text",detail:"ท่านสามารถใช้กล้องโทรศัพท์มือถือ หรือกล้องผ่านช่องทาง LINE@kuber เพื่อใช้ในการสแกน QR Code ชำระสินค้าที่ร่วมรายการ ผ่านการใช้จ่ายด้วย KBC ได้ง่าย ๆ หรือแลกซื้อสินค้าผ่านเว็บไซต์ www.bitkuber.io"},
          {element:"title",size:4,detail:"ใช้เหรียญในการซื้อสินค้า"},
          {element:"img",size:100,detail:"/img/faq/kbc.png"},
          {element:"title",size:4,detail:"สแกนซื้อสินค้าผ่าน LINE@kuber"},
          {element:"img",size:100,detail:"/img/faq/kuber.png"},
          {element:"title",size:4,detail:"แลกซื้อสินค้าได้บน www.bitkuber.io/item"}
        ]},
        {name:"สามารถใช้เหรียญได้ที่ไหนบ้าง",detail:[
          {element:"title",size:3,detail:"สามารถใช้เหรียญได้ที่ไหนบ้าง"},
          {element:"text",detail:"ท่านสามารถใช้เหรียญกับร้านค้าที่ร่วมรายการได้ตามรายการที่แสดงไว้ด้านล่างนี้"},
          {element:"img",size:180,detail:"/img/faq/zilla.png"}
        ]},
        {name:"สามารถดูระยะเวลาคงเหลือในการ เก็บเหรียญได้ที่ไหน",detail:[
          {element:"title",size:3,detail:"สามารถดูระยะเวลาคงเหลือในการ เก็บเหรียญได้ที่ไหน"},
          {element:"text",detail:"ท่านสามารถดูและตรวจสอบได้โดยการกดที่รูป wallet เพื่อดูยอดเงินคงเหลือของ line rich menu"},
          {element:"img",size:250,detail:"/img/faq/wallet.png"}
        ]},
        
        {name:"สามารถโอนเหรียญ KBC ให้คนอื่นได้หรือไม่",detail:[
          {element:"title",size:3,detail:"สามารถโอนเหรียญ KBC ให้คนอื่นได้หรือไม่"},
          {element:"text",size:3,detail:"ระบบไม่รองรับในการทำธุรกรรมนีี้ขออภัยในความสะดวก"}
        ]},
        {name:"สามารถใช้เหรียญ KBC แลกอะไรได้บ้าง",detail:[
          {element:"title",size:3,detail:"สามารถใช้เหรียญ KBC แลกอะไรได้บ้าง"},
          {element:"text",detail:"สามารถใช้สำหรับ Redeem ของรางวัลตามที่กำหนดไว้ และใช้ซื้อสินค้าได้ที่ร้านค้าที่เข้าร่วมรายการ"}
        ]},
        {name:"สามารถสะสมเหรียญไป Redeem ได้หรือไม่",detail:[
          {element:"title",size:3,detail:"สามารถสะสมเหรียญไป Redeem ได้หรือไม่"},
          {element:"text",detail:"ได้ สามารถสะสมเหรียญเพื่อนำไป Redeem ของรางวัลได้ที่บน www.bitkuber.io/item"}
        ]},
        // {name:"วิธีการใช้เหรียญซื้อสินค้า",detail:[
        //   {element:"title",size:3,detail:"วิธีการใช้เหรียญซื้อสินค้า"},
        //   {element:"text",detail:"ท่านสามารถใช้กล้องมือถือเพื่อใช้ในการสแกน QR Code เพื่อทำการชำระสินค้าที่ร่วมรายการผ่านการใช้จ่ายด้วย KBC หรือแลกซื้อสินค้าผ่านเว็บไซค์ www.bitkuber.io"}
        // ]},
        // {name:"วิธีการได้รับเหรียญ KBC",detail:[
        //   {element:"title",size:3,detail:"วิธีการได้รับเหรียญ KBC"},
        //   {element:"text",detail:"เหรียญ KBC ได้รับเฉพาะพนักงาน S1 และ S2 จะได้รับเหรียญตอนสแกนบัตรพนักงานเข้าออฟฟิต หลังจากนั้นเหรียญจะเข้าสู่ Wallet ของท่าน"}
        // ]},
        // {name:"เหรียญที่ไม่ได้นำมาใช้กับร้านค้าที่ร่วมรายการสามารถเอาไปทำอย่างไรได้บ้าง",detail:[
        //   {element:"title",size:3,detail:"เหรียญที่ไม่ได้นำมาใช้กับร้านค้าที่ร่วมรายการสามารถเอาไปทำอย่างไรได้บ้าง"},
        //   {element:"text",detail:"สามารถนำไป Redeem ของรางวัลบน www.itkuber.io ได้"}
        // ]},
        // {name:"เหรียญ KBC มีอายุการใช้งานได้นานเท่าไหร",detail:[
        //   {element:"title",size:3,detail:"เหรียญ KBC มีอายุการใช้งานได้นานเท่าไหร"},
        //   {element:"text",detail:"เหรียญใน Wallet จะมีอายุ 1 ปี นับจากที่มีการเปิด Wallet ครั้งแรก เช่นเปิดกระเป๋าครั้งแรกวันที่ 1 Dec 2023 เหรียญใน Wallet จะหมดอายุในวันที่ 1 Dec 2024"}
        // ]},
      ]}
    ]


    const RenderHeader = () => {
      return (
        <div>
            {id? (
              <Card className="wallet_card" style={{backgroundImage: `url(/img/wallet/KBC.png)`}}>
                <Link to="/liff/kuber/help"><Text style={{color:'white'}}><ArrowLeftOutlined /> {helpList[id.split('-')[0]].name} </Text></Link>
                <br />
                <Title level={2} className="ma-0 mr-10" style={{color:'white'}}>{helpList[id.split('-')[0]].list[id.split('-')[1]].name}</Title>
                <br />
              </Card>
            ) : (
              <Card 
                className="wallet_card" style={{backgroundImage: `url(/img/wallet/KBC.png)`}}
                actions={[
                  <BookTwoTone key="faq" twoToneColor={menuTab=="faq"? "#02d667" : "#66666620"} style={{fontSize:25}} onClick={()=>setMenuTab('faq')} />,
                  <FlagTwoTone key="help" twoToneColor={menuTab=="help"? "#02d667" : "#66666620"} style={{fontSize:25}} onClick={()=>setMenuTab('help')} />
                ]}
              >
                <Text style={{color:'white'}}>Hello {authData.name}</Text><br />
                <Title level={2} className="ma-0 mr-10" style={{color:'white'}}>We’re here to help</Title>
              </Card>
            )}
        </div>
      )
    }


    const onFinish = async(data) => {
      const confirmed = await modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        destroyOnClose:true,
        content: `ต้องการส่งแบบฟอร์มการแจ้งเลยหรือไม่? `,
        okText: 'Confirm',
        cancelText: 'Cancel',
      });
      if(confirmed){
        setWaiting(true)
        const accessToken = await authLiff.getAccessToken()
        fetch(`/api/liff/kuber/help/send`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((data) => {
                setWaiting(false)
                if(data.status==true){
                  form.resetFields();
                  message.success(data.message);
                }else{
                  message.info(data.message);
                }
            })
            .catch((error) => {
                setWaiting(false)
            });
      }
    }

    const onChange = (e) => {
      setAgree(e.target.checked)
    }
    const normFile = (e) => {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e?.fileList;
    };
    const RenderFaqForm = () => {
      var list_support = helpList.filter(item=>item.type=="form")[0].list.map(item=>{
        return {
          label:item.name,
          value:item.name
        }
      })

      return (
        <Card className="mb-2">
          <Row gutter={[12, 12]}>
            <Col span={24}>
            <Spin spinning={waiting} delay={500}>
              <Title level={5}>ต้องการให้ช่วยเหลือด้านไหนคะ</Title>
              <Form form={form} onFinish={onFinish} layout="vertical" autoComplete="off">

                <Form.Item key={'help'} name={'help'} >
                  <Select
                    placeholder="เลือกปัญหาที่พบ"
                    options={list_support}
                    rules={[{ required: true, message: "" }]}
                  />
                </Form.Item>
                <Form.Item key={'amount'} name={'amount'} >
                  <Input placeholder="จำนวนเหรียญ KBC"/>
                </Form.Item>
                <Form.Item key={'comment'} name={'comment'}  rules={[{ required: true }]} >
                  <Input.TextArea
                    showCount
                    maxLength={300}
                    style={{ height: 120 }}
                    placeholder="รายละเอียด"
                    maxLength={450}
                  />
                </Form.Item>
                <Form.Item
                  name="upload"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload name="logo" action="/api/liff/kuber/help/upload" listType="picture">
                    <Button icon={<UploadOutlined />}>แนบรูปภาพ / วีดีโอ</Button>
                  </Upload>
                </Form.Item>

                <Checkbox onChange={onChange} checked={agree}>ฉันยอมรับ</Checkbox>
                <div style={{paddingLeft:25}}>
                  <small>
                    <ol>
                      <li>ในการส่งรายงานนี้ข้าพเจ้าขอรับรองว่าข้อมูลที่แจ้ง เป็นความจริงทุกประการ</li>
                      <li>ระบบ Bitkuber.io มีการบันทึกประวัติคำร้องเรียนของท่าน เพื่อนำไปพัฒนาและปรับปรุงการให้บริการของร้านค้าที่เข้าร่วม</li>
                    </ol>
                  </small>
                </div>
                <Divider />
                <Form.Item>
                    <Button type={!agree? "" : "primary"} size="large" disabled={!agree} htmlType="submit" icon={<CheckCircleOutlined />} block>Submit</Button>
                </Form.Item>
              </Form>
              </Spin>
              {contextHolder}
            </Col>
          </Row>
        </Card>
      )
    }




    const RenderHelp = () => {
      return menuTab=="faq"? (
        <Card className="mb-2">
          <Row gutter={[12, 12]}>
            {loading? (
              <Skeleton paragraph={{ rows: 4 }} />
            ) : (helpList.length >=1? (
              <Col span={24}>
                {
                  helpList.map((item,i)=>{
                    return (
                      <>
                      <Title level={5}>{item.name}</Title>
                      <ol>
                        {
                          item.list.map((node,index)=>{
                            if(i==0){
                              return (<li onClick={()=>setMenuTab('help')}> {index+1} {node.name}</li>)
                            }else{
                              return (<li><Link to={`/liff/kuber/help/${i+'-'+index}`}> {index+1} {node.name}</Link></li>)
                            }
                          })
                        }
                      </ol>
                      <Divider/>
                      </>
                    )
                  })
                }
              </Col>
          ) : (
            <Col span={24}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Col>
          ))}          
          </Row>
        </Card>
      ) : <RenderFaqForm/>
    }


    const RenderHelpContent = () => {
      return (
        <Card className="mb-2">
          <Row gutter={[12, 12]}>
            <Col span={24} style={{minHeight:200}}>
                {helpList[id.split('-')[0]].list[id.split('-')[1]].detail.map(item=>{
                  if(item.element=='title'){
                    return (<Title level={item.size}>{item.detail}</Title>)
                  }else if(item.element=='text'){
                    return (<Text>{item.detail}</Text>)
                  }else if(item.element=='img'){
                    return item.url? (<div><center><Link to={item.url}><img width={item.size} src={item.detail} /></Link></center></div>) : (<div><center><Image width={item.size} src={item.detail} /></center></div>)
                  }else{
                    return '<></>'
                  }
                })}
            </Col>
            <Col span={24}>
                <Divider />
                <div className="text-center p-5"><Title level={5}>ติดต่อสอบถาม <a href="#"><CommentOutlined /> แชทเลย</a></Title></div>
                <Divider />
                <Text type="secondary" style={{fontSize:10}}>บริการให้ความช่วยเหลือมีประโยชน์สำหรับชาว Bitkuber หรือไม่</Text><br />
                <Rate defaultValue={3} character={({ index }) => customIcons[index + 1]} />
            </Col>
          </Row>
        </Card>
      )
    }

    return (
      <LinkAccount liffId={'1657440707-07bROQRB'}>
          <Spin spinning={loading} delay={500}>
            <Row gutter={[0, 0]} className="mb-10">
              <Col span={24}>
                <RenderHeader />
              </Col>
              <Col span={24}>
                {id? <RenderHelpContent /> : <RenderHelp /> }
              </Col>
            </Row>
          </Spin>
      </LinkAccount>
    ); 
};

export default PageLiffKuberWallet;

