import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Empty,
  Typography,
  Space,
  Spin,
  Avatar,
  Descriptions,
  Tag,
  Alert,
  Result,
  Divider,
  Drawer,
  Dropdown,
  Form,
  message
} from "antd";
import {
  DrawerForm,
  ProFormSelect,
  ProFormMoney,
  ProFormText,
  ProFormTextArea,
  ProFormDigit,
  ProForm,
} from "@ant-design/pro-components";
import { CheckOutlined, GoogleOutlined } from "@ant-design/icons";

import { Link } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../contexts/main.jsx";

import * as animationData from '../assets/lottie/done.json'

const { Title, Text } = Typography;

const CardPayComponent = ({dataForm,onClear=false,onSubmit=false}) => {  

    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [amount, setAmount] = useState(0);
    const { authLiff, isLogin, addressFormat, currencyFormat, sleep } = useContext(MainContext);

    const [action, setAction] = useState(0);
    const [form] = Form.useForm();


    const onSet = (values) => {
      if(Number.isInteger(values.amount)){
        if(dataForm.data?.balance>=values.amount){
          setAmount(values.amount)
          setStep(1)
        }else{
          message.info('ยอดเงินในบัญชีของคุณไม่พอ');
        }
      }else{
         message.info('ยอดเงินไม่ถูกต้อง');
      }
    }

    const onCancel = (values) => {
      if(step==1){
        setStep(0)
      }else{
        onClear()
      }
    }

    const CardTicket = ({dataForm}) => {
      return (
          <Result
            icon={<Avatar src={dataForm.data?.avatar || '/icon-192x192.png'} style={{width:100,height:100}}/>}
            title="Redeem ticket from event"
            subTitle={dataForm.data.name}
            style={{padding:0}}
            extra={[
              <Button type="primary" block size="large" onClick={()=>{
                  onSubmit({amount:0})
              }}>
                Redeem
              </Button>
            ]}
          >
          </Result>
      )
    }

    const CardEvent = ({dataForm}) => {
      return (
          <Result
            icon={<Avatar src={dataForm.data?.avatar || '/icon-192x192.png'} style={{width:100,height:100}}/>}
            title="Confirm to Join Event"
            subTitle={dataForm.data.name}
            style={{padding:0}}
            extra={[
              <Button type="primary" block size="large" onClick={()=>{
                  onSubmit({amount:0})
              }}>
                Join
              </Button>
            ]}
          >
          </Result>
      )
    }

    const CardPay = ({dataForm}) => {
      return (
        <>
          {(dataForm.data.amount!='0' || step==1)? (
            <Result
              icon={<Avatar src={dataForm.data?.avatar || '/icon-192x192.png'} style={{width:100,height:100}}/>}
              title="Confirm to Transfer"
              subTitle={(
                  <Descriptions bordered column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }} items={[
                    {
                      label: 'To',
                      children: dataForm.data?.name || 'Kuber'
                    },
                    {
                      label: 'Currency',
                      children: (                
                        <>
                          <Avatar src={`/img/token/${dataForm.data?.currency}.png`} size={20} className="mr-1"/> <Text>{dataForm.data?.currency}</Text>
                        </>

                      )
                    },
                    {
                      label: 'Amount',
                      children: (<Title level={2}>{currencyFormat(amount,2)}</Title>)
                    }
                  ]} />
              )}
              style={{padding:0}}
              extra={[
                <Button type="primary" block size="large" onClick={()=>{onSubmit({amount})}}>
                  Confirm
                </Button>,
                <Button type="text" block size="large" className={'mt-1'} onClick={()=>{onCancel()}}>
                  Cancel
                </Button>
              ]}
            />
          ) : (
            <Result
              icon={<Avatar src={dataForm.data?.avatar || '/icon-192x192.png'} style={{width:100,height:100}}/>}
              title="Amount to Transfer"
              subTitle={`Transfer ${dataForm.data.currency} to ${dataForm.data.name}`}
              style={{padding:0}}
            >
                  
                  <Button type="text" size="small" style={{float:'right'}}>
                    Current Balance : {dataForm.data?.balance || 0} {dataForm.data.currency}
                  </Button>
                  <br clear="all" />
                  <ProForm
                    submitTimeout={2000}
                    onFinish={(values) => onSet(values)}
                    form={form}
                  >

                    <ProFormDigit label={`Amount (${dataForm.data.currency})`} size={'large'} value={dataForm.data.amount} name="amount" min={1} max={100000} fieldProps={{
                      min:1,
                      max:1000,
                      size:'large',
                      inputmode:"numeric",
                      autofocus:"autofocus"
                    }} />
                    <Text type="secondary" style={{mergin:'0 0 5px'}}>{`Transfer Range 1 to 1,000 ${dataForm.data.currency}`}</Text>
                    <Divider />
                  </ProForm>
            </Result>
          )}
        </>
      )
    }

    return (
      <>
          {(dataForm && dataForm.data && ['event','activity'].includes(dataForm.data.type)) && <CardEvent dataForm={dataForm}/>}
          {(dataForm && dataForm.data && ['pay','auth','shop'].includes(dataForm.data.type)) && <CardPay dataForm={dataForm}/>}
          {(dataForm && dataForm.data && ['ticket'].includes(dataForm.data.type)) && <CardTicket dataForm={dataForm}/>}
      </>
    ); 
};

export default CardPayComponent;

