import React, { useEffect, useRef, useState, useContext } from "react"; 
import {
  Row,
  Col,
  Card,
  Button,
  Empty,
  Typography,
  Space,
  Spin,
  Avatar,
  Alert,
  Result,
  message
} from "antd";

import { CheckOutlined, GoogleOutlined, LinkOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../contexts/main.jsx";

import * as animationData from '../assets/lottie/done.json'
import * as animationScand from '../assets/lottie/scand.json'
import * as animationWait from '../assets/lottie/block_confirm.json'
import * as animationError from '../assets/lottie/error.json'
const { Title, Text } = Typography;

const CardViewComponent = ({view='waiting',dataPay=false,onClear=false}) => {  
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);

    const { authLiff, isLogin, sleep } = useContext(MainContext);

    // const onRecheck = async() =>{
    //   await sleep(5000)
    //   authLiff
    //     .sendMessages([
    //       {
    //         type: "text",
    //         text: "slip",
    //       },
    //     ])
    //     .then(() => {
    //       setStep(2)
    //     })
    //     .catch((err) => {
    //       console.log("error", err);
    //     });
    // }

    const RenderWaiting = ({onClear,dataPay}) => {
        return (
            <Result
              icon={<Lottie options={{
                  animationData: animationWait,
                  loop: true,
                  autoplay: true,
                }} height={200} width={200}/>}
              title="Waiting"
              subTitle="กำลังรอ confirm ธุรกรรม"
            />
        )
    }

    const RenderSuccess = ({onClear,dataPay}) => {
        return (
            <Result
              icon={<Lottie options={{
                  animationData: animationData,
                  loop: false,
                  autoplay: true,
                }} height={200} width={200}/>}
              title="SUCCESS"
              subTitle="ทำธุรกรรมสำเร็จ"
              extra={[
                <Button type="primary" block size="large" onClick={onClear? onClear : ()=>{
                    authLiff.closeWindow();
                }}>
                  Close
                </Button>
              ]}
            />
        )
    }

    const RenderScandError = ({onClear,dataPay}) => {
        return (
            <Result
              icon={<Lottie options={{
                  animationData: animationError,
                  loop: false,
                  autoplay: true,
                }} height={120} width={120}/>}
              title="ERROR"
              subTitle={dataPay.message || 'ไม่สามารถทำธุรกรรมได้กรุณาลองใหม่อีกครั้ง'}
              extra={[
                <Button type="primary" block size="large" onClick={onClear? onClear : ()=>{
                    authLiff.closeWindow();
                }}>
                  Close
                </Button>
              ]}
            />
        )
    }
    return (
        <>
          {view=='waiting' && <RenderWaiting onClear={onClear} dataPay={dataPay}/>}
          {view=='success' && <RenderSuccess onClear={onClear} dataPay={dataPay}/>}
          {view=='error' && <RenderScandError onClear={onClear} dataPay={dataPay}/>}
        </>
    ); 
};

export default CardViewComponent;

