import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Typography,
  Select,
  Input,
  InputNumber,
  Table,
  Space,
  Checkbox,
  List,
  Spin,
  Avatar,
  Radio,
  Tag,
  Alert,
  Skeleton,
  FloatButton,
  Carousel,
  Result,
  Divider,
  Drawer,
  Dropdown,
  Upload,
  Rate,
  Image,
  Modal,
  message
} from "antd";

import { CloseCircleOutlined, QuestionCircleOutlined,PlusOutlined, MinusCircleOutlined, UploadOutlined, ExclamationCircleOutlined, CheckCircleOutlined, CommentOutlined, BookTwoTone, FlagTwoTone, ArrowLeftOutlined, FrownOutlined, MehOutlined, SmileOutlined, CheckOutlined, SendOutlined, GoogleOutlined, ProfileTwoTone, TagsTwoTone, TrophyTwoTone, WalletTwoTone } from "@ant-design/icons";
import { Link, useParams } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../../../../contexts/main.jsx";
import LinkAccount from '../../../../components/LinkAccount.jsx'
import CardView from '../../../../components/CardView.jsx'
const customIcons = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};
const list_price = {
  "ขวดแก้ว":3,
  "ขวดพลาสติก (300ml)":1,
  "ขวดพลาสติก (600ml)":1,
  "ขวดพลาสติก (1500ml)":2
}
const { Title, Text, Paragraph } = Typography;
const PageLiffKuberFormKhaya = () => {  
    let { id } = useParams();
    const [form] = Form.useForm();
    const [ loading, setLoading ] = useState(false);
    const [ waiting, setWaiting ] = useState(false);
    const [ agree, setAgree ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ formData, setFormData ] = useState(false);
    const [ menuTab, setMenuTab ] = useState('list');
    const [ modal, contextHolder ] = Modal.useModal();
    const { authLiff, authData, isLogin, addressFormat } = useContext(MainContext);

    useEffect(() => {  
      if(isLogin==true){
         setLoading(false)
      }
    }, [isLogin]);  

    const RenderHeader = () => {
      return (
        <div>
              <Card className="wallet_card" style={{backgroundImage: `url(/img/wallet/KBC.png)`}}>
                <Text style={{color:'white'}}>Hello krit.chakkrit</Text><br />
                <Title level={2} className="ma-0 mr-10" style={{color:'white'}}>Event : Khaya x Kuber </Title>
              </Card>
        </div>
      )
    }

    const onConfirm = async(data) => {
      var data = form.getFieldsValue()
      var all_data = data.list.filter(item=>item.amount && item.type)
      var total = all_data.reduce((a, c) => a + list_price[c.type]*c.amount,0)
      data.amount = total
      data.data = all_data
      setFormData(data)
    }

    const onFinish = async() => {
      const data = formData
      const confirmed = await modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        destroyOnClose:true,
        content: `ต้องการส่งแบบฟอร์มเลยหรือไม่? `,
        okText: 'Confirm',
        cancelText: 'Cancel',
      });
      if(confirmed){
        setWaiting(true)
        const accessToken = await authLiff.getAccessToken()
        fetch(`/api/liff/kuber/form_khaya/send`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((data) => {
                setWaiting(false)
                if(data.status==true){
                  form.resetFields();
                  setFormData(false)
                  setAgree(false)
                  message.success(data.message);
                }else{
                  message.info(data.message);
                }
            })
            .catch((error) => {
                setWaiting(false)
            });
      }
    }

    const onChange = (e) => {
      setAgree(e.target.checked)
    }
    const normFile = (e) => {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e?.fileList;
    };




    const RenderViewForm = () => {
      return (
        <Card className="mb-2">
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Spin spinning={waiting} delay={500}>
                {!formData? <RenderViewFormInput/> : <RenderViewFormSubmit/>}
              </Spin>
              {contextHolder}
            </Col>
          </Row>
        </Card>
      )
    }

    const RenderViewFormInput = () => {
      const list_form = [{
        label:"ขวดแก้ว",
        value:"ขวดแก้ว"
      },
      {
        label:"ขวดพลาสติก (300ml)",
        value:"ขวดพลาสติก (300ml)"
      },
      {
        label:"ขวดพลาสติก (600ml)",
        value:"ขวดพลาสติก (600ml)"
      },
      {
        label:"ขวดพลาสติก (1500ml)",
        value:"ขวดพลาสติก (1500ml)"
      }]

      return (
          <Form form={form} onFinish={onConfirm}  initialValues={{ items: [{}] }} layout="vertical" autoComplete="off">
            <Form.Item
              name="email"
              label="Email (Bitkub Email)"
              rules={[{ required: true, message: 'Missing Email' }]}
            >
              <Input placeholder="Email" style={{width:'100%'}}/>
            </Form.Item>
            <Divider />
            <Form.List name="list">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'type']}
                        rules={[{ required: true, message: 'Missing type' }]}
                      >
                        <Select
                            placeholder="ประเภท"
                            style={{width:180}}
                            options={list_form}
                          />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'amount']}
                        rules={[{ required: true, message: 'Missing amount' }]}
                      >
                        <InputNumber placeholder="จำนวน" style={{width:100}}/>
                      </Form.Item>
                      <Button type={"primary"} onClick={() => remove(name)}  icon={<MinusCircleOutlined />}></Button>
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Record
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>


            <Form.Item
              name="upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload name="logo" action="/api/liff/kuber/form_khaya/upload" listType="picture">
                <Button icon={<UploadOutlined />}>แนบรูปภาพ / วีดีโอ</Button>
              </Upload>
            </Form.Item>

            <Checkbox onChange={onChange} checked={agree}>ฉันยอมรับ</Checkbox>
            <div style={{paddingLeft:25}}>
              <small>
                <span>ในการส่งแบบฟอร์มนี้ข้าพเจ้าขอรับรองว่าข้อมูลที่แจ้ง เป็นความจริงทุกประการ<br/>
                ระบบ Bitkuber.io มีการบันทึกประวัติการทำรายการของท่าน เพื่อนำไปทำเป็นรายงาน</span>
              </small>
            </div>

            <Divider />
            <Form.Item>
                <Button type={!agree? "" : "primary"} size="large" disabled={!agree} htmlType="submit" icon={<CheckCircleOutlined />} block>Submit</Button>
            </Form.Item>

          </Form>
      )
    }

    const RenderViewFormSubmit = () => {


      return (
        <Result
          icon={<QuestionCircleOutlined />}
          title="Confirm?"
          subTitle={"ยืนยันการทำรายการส่งเหรียญจำนวน "+formData.amount+" KBG ให้ "+formData.email+"?"}
          extra={[
             <Button type="primary" onClick={onFinish}>
              Confirm
            </Button>,
            <Button onClick={()=>{
                setFormData(false)
            }}>
              Close
            </Button>
          ]}
        >
          <div className="desc">
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                รายการทั้งหมด ({formData.data.length} รายการ)
              </Text>
            </Paragraph>

            {formData?.data?.map(item=>{
              return (
                <Paragraph>
                  <PlusOutlined /> {item.type} จำนวน {item.amount} = {list_price[item.type]*item.amount} KBG
                </Paragraph>
              )
            })}
          </div>
        </Result>
      )
    }



    // return (
    //   <Row gutter={[0, 0]} className="mb-10">
    //     <Col span={24}>
    //       <RenderHeader />
    //     </Col>
    //     <Col span={24}>
    //       <RenderViewForm />
    //     </Col>
    //   </Row>
    // ); 
    return (
      <LinkAccount liffId={'1657440707-7WW5285p'}>
          <Spin spinning={loading} delay={500}>
            <Row gutter={[0, 0]} className="mb-10">
              <Col span={24}>
                <RenderHeader />
              </Col>
              <Col span={24}>
                <RenderViewForm />
              </Col>
            </Row>
          </Spin>
      </LinkAccount>
    ); 
};

export default PageLiffKuberFormKhaya;

