import React, { useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  Divider
} from "antd";
import { Link, useParams } from 'react-router-dom'
import { MiniMap, TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'

const Page = () => {
	let { id } = useParams();

	// /img/liff/event/hodl_day/1.png
	// /img/liff/event/hodl_day/1_img.png
	const data_event = {
		'1':{
			node:1,
			detail:[
				`<p>กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นทีละสี สีละ 4 คน</li>
				<li>2. มีเวลา 1 นาทีต่อเกม ทำแต้มให้ได้มากที่สุด!</li>
				<li>3. กรุณาอย่าปีนเข้าไปในพื้นที่ของแป้นห่วง</li>
				<li>4. หลังจากจบเกมของสีตัวเองแล้ว กรุณาเปลี่ยนรอบให้สีถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : คะแนน</span>
				</p>`
			]
		},
		'2':{
			node:2,
			detail:[
				`<p>
				กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นพร้อมกัน 2 สี สีละ 1 คน แข่งขันแบบ VS เพื่อหาผู้ชนะ-แพ้</li>
				<li>2. มีเวลา 1 นาทีต่อเกม ทำคะแนนให้ได้มากที่สุด</li>
				<li>3. ต้องสวมถุงเท้ากันลื่นของทาง BEAT Active เท่านั้น</li>
				<li>4. ห้ามผลักหรือชนผู้เล่นคนอื่น</li>
				<li>5. หลังจากจบเกมแล้ว กรุณาเปลี่ยนรอบให้ผู้แข่งรอบถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : คะแนน</span>
				</p>`
			]
		},
		'3':{
			node:3,
			detail:[
				`<p>
				กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นพร้อมกัน 4 สี สีละ 1 คน</li>
				<li>2. มีเวลา 1 นาทีต่อเกม ทำเวลาได้ให้ดีที่สุด</li>
				<li>3. หลังจากจบเกมแล้ว กรุณาเปลี่ยนรอบให้ผู้แข่งรอบถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : เวลา</span>
				</p>`
			]
		},
		'4':{
			node:4,
			detail:[
				`<p>
				กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นพร้อมกัน 4 สี สีละ 1 คน</li>
				<li>2. มีเวลา 1 นาทีต่อเกม ทำเวลาได้ให้ดีที่สุด</li>
				<li>3. หลังจากจบเกมแล้ว กรุณาเปลี่ยนรอบให้ผู้แข่งรอบถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : คะแนน</span>
				</p>`
			]
		},
		'5':{
			node:5,
			detail:[
				`<p>กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นทีละสี สีละ 4 คน</li>
				<li>2. เวลาเริ่มนับจากที่คันแรกออกจากจุดเริ่มต้น และหยุดเวลา เมื่อคันสุดท้ายมาถึงเส้นชัย ทำเวลาให้ได้น้อยที่สุด!</li>
				<li>3. ใช้รถคันใหญ่ 2 คัน และคันเล็ก 2 คัน</li>
				<li>4. ขนาดรถและผู้เล่นต้องเหมาะสมกัน</li>
				<li>4. ห้ามเริ่มปั่น ก่อนที่ Staff จะส่งสัญญาณเริ่ม</li>
				<li>5. ห้ามชนผู้เล่นคนอื่น</li>
				<li>6. หลังจากจบเกมของสีตัวเองแล้ว กรุณาเปลี่ยนรอบให้สีถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : เวลา</span>
				</p>`
			]
		},
		'6':{
			node:6,
			detail:[
				`<p>กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นทีละ 2 สี สีละ 1 คน แข่งขันแบบ VS เพื่อหาผู้ชนะ-แพ้</li>
				<li>2. ผู้เข้าแข่งขันจะต้องมีทักษะ และความชำนาญในการปีนผา</li>
				<li>3. ผู้เข้าแข่งขันจะต้องปีนขึ้นไปหยิบธง Bitkub HODL Day Season 4 สีใดที่หยิบธงได้ก่อนจะเป็นผู้ชนะ</li>
				<li>4. ห้ามผลักหรือชนผู้เล่นคนอื่น</li>
				<li>5. ผู้เล่นจะต้องสวมรองเท้า และอุปกรณ์รักษาความปลอดภัยขณะเล่นทุกครั้ง</li>
				<li>6. หลังจากจบเกมของสีตัวเองแล้ว กรุณาเปลี่ยนรอบให้สีถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : สีใดที่หยิบธงได้ก่อนจะเป็นผู้ชนะ</span>
				</p>`
			]
		},
		'7':{
			node:7,
			detail:[
				`<p>กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นทีละสี สีละ 4 คน</li>
				<li>2. มีเวลา 1 นาทีต่อเกม ทำแต้มให้ได้มากที่สุด!</li>
				<li>3. ห้ามยืนหรือปีนใต้ผู้เล่นคนอื่น</li>
				<li>4. หลังจากจบเกมของสีตัวเองแล้ว กรุณาเปลี่ยนรอบให้สีถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : คะแนน</span>
				</p>`
			]
		},
		'8':{
			node:8,
			detail:[
				`<p>กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นทีละสี สีละ 4 คน</li>
				<li>2. เตะลูกบอลไปที่เป้าหมาย ห้ามเตะหรือโยนลูกบอลใส่ผู้เล่นคนอื่น</li>
				<li>3. ห้ามเดินข้ามเส้นเข้าไปในพื้นที่เกม</li>
				<li>4. หลังจากจบเกมของสีตัวเองแล้ว กรุณาเปลี่ยนรอบให้สีถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : คะแนน</span>
				</p>`
			]
		}
	}


 
  return (
  	<LinkAccount liffId={'1657440707-1KdbrBb9'}>
	  	<Row gutter={[0, 0]} className="mb-10">
          <Col span={24} style={{backgroundColor:"#C95020",textAlign:'center',height:70}}>
          	<img src={"/img/liff/event/hodl_day/"+id+".png"} style={{margin:'auto'}}/>
          </Col>
          <Col span={24}>
            <div style={{padding:40,textAlign:'center'}}>
	          	<img src={"/img/liff/event/hodl_day/"+id+"_img.png"} style={{width:'80%',margin:'auto'}}/>
	          	<br />
	          	<div dangerouslySetInnerHTML={{__html:data_event[id].detail}}></div>
	          	<Divider />
	          	<Link to={"/liff/kuber/event/hodl_day/map"}>
	          		<Button type={"primary"} size="large" block>Back</Button>
	          	</Link>
          	</div>
          </Col>
        </Row>
    </LinkAccount>
  );
};


export default Page;