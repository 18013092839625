

export const info = {th:"&nbsp;&nbsp;&nbsp;ในการเข้าร่วมกิจกรรม <strong>Find Your Bitkub Soulmate</strong> พนักงานจะถูกถ่ายภาพและจัดเก็บข้อมูลที่ระบุในแบบสอบถามฉบับนี้เพื่อใช้ในการลงทะเบียนและเพื่อประมวลผลจับคู่ Bitkub soulmate นอกจากนี้ ในพื้นที่จัดกิจกรรมจะมีการถ่ายภาพและวีดีโอเพื่อเก็บภาพบรรยากาศและเผยแพร่ไปยังสื่อสังคมออนไลน์ของบริษัท เช่น  Facebook, IG และ LinkedIn หากพนักงานคนใดไม่ประสงค์ให้ถูกถ่ายภาพหรือวีดีโอ โปรดแจ้งไปยัง People Team หรือช่างภาพ ณ บริเวณที่จัดกิจกรรม",
en:"&nbsp;&nbsp;&nbsp;To participate in the <strong>Find Your Bitkub Soulmate</strong> event, Employees will be photographed and stored information provided in this questionnaire for registration and Bitkub soulmate matching purposes. In addition, photographs and videos will be taken in the event space to capture the atmosphere and disseminate to the media. The company's social networks such as Facebook, IG and LinkedIn. If any employee does not want to be photographed or video Please notify the People Team or the photographer at the event location."
}
export const choice = [
  {
    name:'choice_1',
    label:'สถานะ | Status',
    choice:[
      'โสด | Single',
      'มีคู่ | In a relationship',
      'ไม่สามารถระบุได้ | It’s complicated'
    ]
  },{
    name:'choice_2',
    label:'นิยามความเป็นตัวเอง',
    choice:[
      'ความเป็นผู้ใหญ่',
      'โลกส่วนตัวสูง',
      'ฉลาดหัวไว',
      'ชอบเข้าสังคม',
      'อ่อนไหวง่าย'
    ]
  },{
    name:'choice_3',
    label:'ข้อไหนบ่งบอกนิสัยของคุณได้มากที่สุด',
    choice:[
      'เฟรนด์ลี่',
      'Productive',
      'ภายนอกเข้มแข็ง ภายในอ่อนแอ',
      'ชอบแก้ไขปัญหา',
      'ชอบวิเคราะห์คาดเดา'
    ]
  },{
    name:'choice_4',
    label:"คุณชอบสื่อสารผ่านทางไหน | How do you like to communicate?",
    choice:[
      'Phone',
      'Facebook',
      'IG',
      'Line',
      'SMS'

    ]
  },{
    name:'choice_5',
    label:'เครื่องดื่มที่คุณชอบที่สุด | What\'s your favorite drink?',
    choice:[
      'กาแฟ | Coffee',
      'โกโก้ | Cacao',
      'ชาเขียว | Green tea',
      'ชานม | Mike tea',
      'เบียร์&ไวน์ | Beer&Wine'
    ]
  },{
    name:'choice_6',
    label:'คุณนักกินเป็นสายไหน',
    choice:[
      'Street food',
      'ชาบู ชาบู',
      'หมูกะทะ',
      'อาหารจานเดี่ยว'
    ]
  },{
    name:'choice_7',
    label:'แนวเพลงที่คุณชอบฟัง',
    choice:[
      'K-Pop',
      'T-Pop',
      'สากล',
      'Jazz',
      'Acoustic'
    ]
  },{
    name:'choice_8',
    label:'ชอบฤดูไหนมากที่สุด',
    choice:[
      'ฤดูหนาว',
      'ฤดูร้อน',
      'ฤดูฝน',
      'ฤดูใบไม้ผลิ'
    ]
  },{
    name:'choice_9',
    label:'ในวันหยุดคุณจะเลือกไปเที่ยวที่ไหน | where would you like to go on holiday?',
    choice:[
      'ทะเล | Sea',
      'ภูเขา | Mountain',
      'ห้างสรรพสินค้า | Mall',
      'คาเฟ่ | Cafe',
      'วัด | Temple'
    ]
  },{
    name:'choice_10',
    label:'เดทแรกคุณจะไปเดทที่ไหน | Where will you go on your first date?',
    choice:[
      'โรงหนัง | Cinema',
      'สวนสนุก | Theme park',
      'คาเฟ่ / ร้านอาหาร | Cafe / Restaurant',
      'สวนสาธารณะ | Park',
      'วัด | Temple'
    ]
  }
]