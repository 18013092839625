import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Checkbox,
  List,
  Spin,
  Avatar,
  Radio,
  Alert,
  Result,
  Dropdown,
  message
} from "antd";

import { CheckOutlined, GoogleOutlined, LinkOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom'
import Lottie from 'react-lottie';
import liff from '@line/liff';
import { MainContext } from "../../../../contexts/main.jsx";
import LinkAccount from '../../../../components/LinkAccount.jsx'


const { Title, Text } = Typography;

const PageLiffKuberLink = () => {  

    const { authData } = useContext(MainContext);

    const RenderFormLinked = () => {
        return (
            <Result
              icon={<LinkOutlined />}
              title="Linked"
              subTitle={`บัญชี line ของท่านได้ทำการ link กับ email ${authData.email} ก่อนหน้านี้แล้ว`}
              extra={[
                <Button type="primary" block size="large" onClick={()=>{
                    liff.closeWindow();
                }}>
                  Close
                </Button>
              ]}
            />
        )
    }

    return (
        <LinkAccount liffId={'1657440707-KbM40a4W'}>
          <Card className="mb-5" size={'small'}>
              <RenderFormLinked/>}
          </Card>
        </LinkAccount>
    ); 
};

export default PageLiffKuberLink;

