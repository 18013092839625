import { useRef, useEffect, useState, useCallback, useContext } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Drawer,
  InputNumber,
  Steps,
  Checkbox,
  Layout,
  List,
  Tag,
  Spin,
  Avatar,
  Radio,
  Alert,
  Menu,
  Badge,
  Tour,
  Result,
  Dropdown,
  message
} from "antd";
import { CheckOutlined, GoogleOutlined } from "@ant-design/icons";
import { useNavigate , Link } from 'react-router-dom'
import liff from '@line/liff';
import Lottie from 'react-lottie';
import Webcam from "react-webcam";
import { info, choice } from "./index.const.jsx";
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
import * as animationData from '../../../../../assets/lottie/love.json'
import './index.css';

const { Title, Text } = Typography;

const Page = () => {

  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const [form_concent] = Form.useForm();
  const [form_poll] = Form.useForm();

  const [formDataBenefitEdit, setFormDataBenefitEdit] = useState({});
  const [formDataBenefitIndex, setFormDataBenefitIndex] = useState(0);

  const [disabled, setDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [stepForm, setStepForm] = useState(0);

  const [mateList, setMateList] = useState([]);
  const [myInfo, setMyInfo] = useState({});

  const [mounted, setMounted] = useState(false);
  const [authData, setAuthData] = useState(false);

  const [img, setImg] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const { authLiff, isLogin, activePay, sleep } = useContext(MainContext);

  useEffect(() => {  
    if(isLogin==true){
       initCheck()
    }
  }, [isLogin]); 


  const initCheck = async() => {
    setLoading(true)
      const accessToken = await authLiff.getAccessToken()
      fetch('/api/liff/kuber/event/bitkub_mate/check', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
        }).then(async response => {
          const data = await response.json();
          console.log('data=====>',data)
          setLoading(false)
          if(!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          if(data.status == false) {
            setStepForm(4)
            setMounted(true)
          } else {
            setStepForm(data.res.step)
            setMyInfo(data.res.data)
            setMateList(data.res.list)
            setMounted(true)
          }
        }).catch(error => {
          setLoading(false)
          console.log('error=>call api',error)
        })
      // setStepForm(0)
      // setMateList([])
      // setMounted(true)
  }


  useEffect(() => {
    var found = choice.filter(item=>value[item.name] && value[item.name]!=0)
    console.log(found.length)
    if(found.length==choice.length){
      setDisabled(false)
    }
  },[value])

  const onChangeConcent = (e) => {
    setChecked(e.target.checked);
    setDisabled(!e.target.checked)
  };
  const onFinishConcent = (values) => {
    setStep(1)
    setStepForm(1)
    setDisabled(false)
  }

  const RenderFormConcent = () => {
    return (
      <Form form={form_concent} layout="vertical" onFinish={onFinishConcent}>
        <Row gutter={[24, 24]} className="mb-10">
          <Col span={24}>
              <Card className="mb-5">
                <div dangerouslySetInnerHTML={{__html: info.th}} className="mb-5"></div>
                <div dangerouslySetInnerHTML={{__html: info.en}} className="mb-5"></div>
                <Checkbox checked={checked} onChange={onChangeConcent}>
                  ฉันรับทราบ | I acknowledge
                </Checkbox>
              </Card>
          </Col>
          <Col span={24} align="center">
            <Button type="primary" block size="large" htmlType="submit" className={"submit "+(disabled? 'disabled' : '')} disabled={disabled}>
              GET STARTED
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }

  useEffect(() => {
    if(timeLeft===0){
       capture()
       setTimeLeft(null)
       setDisabled(false)
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);


  const capture = useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImg(imageSrc)
      setStep('2')
    },
    [webcamRef]
  );

  const onTake = () => {
    setDisabled(true)
    if(step==1){
      setImg(false)
      setTimeLeft(5)
    }else if(step==2){
       onSubmitTake()
    }
  }

  const onReTake= () => {
    setDisabled(true)
    setStep('1')
    setImg(false)
    setTimeLeft(5)
  }

  const onSubmitTake = () => {
    setDisabled(true)
    if(img){
      setStep('3')
      setAnimate(true)
      setTimeout(() => {
        setAnimate(false)
        setStepForm(2)
      }, 2000)
    }
  }


  const onFinishPool = (values) => {
    initSave()
  }

  const initSave = async() => {
    setDisabled(true)
    const accessToken = await authLiff.getAccessToken()
    fetch('/api/liff/kuber/event/bitkub_mate/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          img,
          ...value
        }),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data);
      setStepForm(3)
    })
    .catch((error) => {
      console.error('Error:', error);
      setDisabled(false)
    });
  }


 const onChange = (e) => {
    // e.target.value
    console.log(e.target)
    var updatedValue = {}
    updatedValue[e.target.name]=e.target.value
    setValue(shopCart => ({
      ...value,
      ...updatedValue
    }));
  };


  const RenderFormPoll = () => {
    return (
      <div className="event-bitkub_mate-block">
        <Title level={3}>Matching Q&A</Title>
        <Form form={form_poll} layout="vertical" onFinish={onFinishPool}>
          <Row gutter={[24, 24]} className="mb-10">
            <Col span={24}>

              {choice.map((item,index_key)=>{
                return (
                  <Card className="mb-3">
                    <Row gutter={[12, 0]}>
                      <Col span={24} align="left">
                        <Title level={5}><span>{index_key+1}</span>{item.label}</Title>
                      </Col>
                      <Col span={24} align="left">
                            <Form.Item name={item.name} rules={[{required: true, message: ""}]}>
                              <Radio.Group onChange={onChange} value={value[item.name]} name={item.name}>
                                <Space direction="vertical" className="w-100">
                                  {item.choice.map((node,index)=>{
                                    return (<Radio value={index+1} key={item.name+'_'+index}>{node}</Radio>)
                                  })}
                                </Space>
                              </Radio.Group>
                            </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                )
              })}


            </Col>
            <Col span={24} align="center">
              <Button type="primary" block size="large" htmlType="submit" className={"submit "+(disabled? 'disabled' : '')} disabled={disabled}>
                SUBMIT
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }


  const RenderFormResult = () => {
    return (
      <Card className="mb-5">
        <Result
          icon={<Lottie options={{
              animationData: animationData,
              loop: false,
              autoplay: true,
            }} height={150} width={150}/>}
          title="Thank you for registering!"
          subTitle="สามารถเข้ามาสแกนดูคำตอบว่า ใครคือ Bitkub Soulmate ของคุณ ได้ในวันที่ 
  14 กุมภาพันธ์ 2567 ที่ออฟฟิศ | Able to scan to see who is your Bitkub Soulmate on 14 February 2024 at the office."
          extra={[
            <Button type="primary" block size="large" className={"submit"} onClick={()=>{
                authLiff.closeWindow();
            }}>
              Close
            </Button>
          ]}
        />
      </Card>
    )
  }

  const RenderFormExpire = () => {
    return (
      <Card className="mb-5">
        <Result
          status="error"
          title="Token expired"
          subTitle="QR code มีอายุการใช้งาน 10 วินาที กรุณาทำการ scand ใหม่อีกครั้ง | QR code valid for 10 seconds, please scand again."
          extra={[
            <Button type="primary" block size="large" className={"submit"} onClick={()=>{
                authLiff.closeWindow();
            }}>
              Close
            </Button>
          ]}
        />
      </Card>
    )
  }

  const RenderFormBlank = () => {
    return (
      <Card className="mb-5">
        <Result
          status="error"
          title="ไม่พบผู้ที่มีความชอบเหมือนกัน"
          subTitle="ถึงแม้ว่าตอนนี้จะยังไม่มีคู่แต่ก็จะเป็นกำลังใจให้ :)"
          extra={[
            <Button type="primary" block size="large" className={"submit"} onClick={()=>{
                authLiff.closeWindow();
            }}>
              Close
            </Button>
          ]}
        />
      </Card>
    )
  }
  const RenderFormEnd = () => {
    return (
      <Card className="mb-5">
        <Result
          status="error"
          title="Registration Not found"
          subTitle="ไม่พบรายการลงทะเบียนของท่านในช่วงเวลากิจกรรม | Can't find your registration list during the event period.."
          extra={[
            <Button type="primary" block size="large" className={"submit"} onClick={()=>{
                authLiff.closeWindow();
            }}>
              Close
            </Button>
          ]}
        />
      </Card>
    )
  }

  const RenderFormRank = () => {
    const list_status = {
        1:'Single',
        2:'In a relationship',
        3:'It’s complicated'
    }
    return (
      <div style={{marginTop: '-50px'}}>
        <div className="event-bitkub_mate-desc">Matching </div>
        <div className="rank_avatar">
          <div className="rank_avatar_percent">
            <div className="text">100%</div>
          </div>
          <div className="rank_avatar_item">
            <div className="rank_avatar_image">
              <Avatar src={'/api/event/bitkub_mate/img/'+myInfo.user_id}  className="avatar_border" size={150} />
            </div>
            <div className="rank_avatar_image">
              <Badge.Ribbon text={mateList[0].status || 'โสด'} color="pink">
              <Avatar src={'/api/event/bitkub_mate/img/'+mateList[0].user_id}  className="avatar_border" size={150} />
              </Badge.Ribbon>
            </div>
          </div>
          <div className="rank_avatar_detail">
              <div className="avatar-info">
                  <Text style={{color:'#ffffff'}} ellipsis={true}>{myInfo.name}</Text>
                  {/*<Tag color="#F5A2BD">{mateList[0].status}</Tag>*/}
              </div>

              <div className="avatar-info">
                  <Text style={{color:'#ffffff'}} ellipsis={true}>{mateList[0].name}</Text>
                  {/*<Tag color="#F5A2BD">{mateList[0].status}</Tag>*/}
              </div>
          </div>
        </div>
        {/*<div className="rank_list">
          <List
              itemLayout="horizontal"
              dataSource={mateList.map((item,index)=>{
                return index==0? false : item
              }).filter(item=>item!=false)}
              renderItem={(item) => (
                <List.Item
                  actions={[<space className="rank_list_percent">
                  <Text>MATCHING</Text> <br/><Text>{item.percent}%</Text>
                </space>]}
                >
                    <List.Item.Meta
                      avatar={<Avatar src={'/api/event/bitkub_mate/img/'+item.user_id} color="#D9D9D9" />}
                      title={item.name}
                      description={item.status}
                    />
                </List.Item>
              )}
            />
        </div>*/}
        <div style={{height:50}}></div>
          <Button type="primary" block size="large" className={"submit"} onClick={()=>{
                authLiff.closeWindow();
            }}>
              Close
          </Button>
      </div>
    )
  }
// {/*<LinkAccount liffId={'1657440707-me9RBPRD'}>*/}
  return (
    <LinkAccount liffId={'1657440707-me9RBPRD'}>
      <div className="event-bitkub_mate">
        <Spin spinning={loading} delay={500}>
            <div className="event-bitkub_mate-body">
              {mounted && (<div className="event-bitkub_mate-inner">
                <div className="event-bitkub_mate-title"></div>

                {stepForm==0 && <RenderFormConcent />}
                {stepForm==1 && (
                  <Row gutter={[24, 24]} className="mb-10">
                    <Col span={24}>
                    <Card>
                      <div style={{width:300,height:300,borderRadius: 20,margin:'20px auto',overflow:'hidden',position: 'relative'}} >
                        <Webcam
                          audio={false}
                          height={300}
                          ref={webcamRef}
                          style={{position: 'absolute'}}
                          className={step=='1'? 'view-show' : 'view-hide'}
                          screenshotFormat="image/jpeg"
                          mirrored={true}
                          width={300}
                          videoConstraints={{
                            width: 300,
                            height: 300,
                            facingMode: "user"
                          }}
                        />
                        {step=='1' && (<div style={{width:300,height:300,lineHeight:'300px',textAlign:'center',position: 'absolute',left:0,right:0,zIndex:999,fontSize:150,color:'white',backgroundColor:'rgba(0,0,0,0.2)'}} onClick={()=>onTake()}>
                        {timeLeft}
                        </div>)}
                        {step=='2' && (<img src={img} style={{position: 'absolute'}} className={step=='2'? 'view-show' : 'view-hide'} />)}
                        {step=='3' && (<Lottie options={{
                          animationData: animationData,
                          loop: false,
                          autoplay: true,
                        }} height={300} width={300}/>)}
                      </div>
                      <div className="mt-10">
                      <Text>กรุณาถ่ายภาพเพื่อใช้ในการแสดงในระบบสำหรับกิจกรรม</Text>       
                      </div>  
                      </Card> 
                    </Col>
                    <Col span={24} align="center">
                      <Button type="primary" block size="large" className={"submit "+(disabled? 'disabled' : '')} disabled={disabled}  onClick={()=>onTake()}>
                        {step=='2' ? 'Use this photo' : 'Take a Photo'}
                      </Button>
                      {step=='2' && (<Button type="link" block size="large" className="mt-1"  onClick={()=>onReTake()}>
                        Not use this photo
                      </Button>)}
                    </Col>
                  </Row>
                )}
                {stepForm==2 && <RenderFormPoll />}
                {stepForm==3 && <RenderFormResult />}
                {stepForm==4 && <RenderFormExpire />}
                {stepForm==5 && <RenderFormRank />}
                {stepForm==6 && <RenderFormEnd />}
                {stepForm==7 && <RenderFormBlank />}
                <div className={"event-bitkub_mate-footer"+(stepForm==1? " photo" :"")}></div>
              </div>)}
            </div>
        </Spin>
      </div>
    </LinkAccount>
  );
  // {/*</LinkAccount>*/}
};

export default Page;
