import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Empty,
  Typography,
  Space,
  Spin,
  Avatar,
  Tag,
  Alert,
  Result,
  Divider,
  Skeleton,
  Drawer,
  message
} from "antd";

import { CheckOutlined, GoogleOutlined } from "@ant-design/icons";

import { Link } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../contexts/main.jsx";
import CardScan from "../components/CardScan.jsx"


const { Title, Text } = Typography;
const CardTicketComponent = () => {  

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const [ticketList,setTicketList] = useState([])

    const { authLiff, isLogin } = useContext(MainContext);

    useEffect(() => {  
      if(isLogin==true){
         init();
      }
    }, [isLogin]);    

    const showDrawer = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    const init = async() => {
        await onLoad()
    } 

    const onLoad = async() => {
        setLoading(true)
        const accessToken = await authLiff.getAccessToken()
        fetch("/api/liff/kuber/list/ticket", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            .then((response) => response.json())
            .then((data) => {
                setTicketList(data.data)
                setLoading(false)
                console.log('Success:', data);
            })
            .catch((error) => {
                setLoading(false)
                console.error('Error:', error);
            });
    } 

    const RenderTicket = () => {
      return (
        <Card className="mb-2">
          <Row gutter={[12, 12]}>
            <Col span={24} align="left">
              <Title level={3}>Ticket & Voucher</Title>
            </Col>
            {loading? (
                <Skeleton avatar paragraph={{ rows: 1 }} />
            ) : (ticketList.length >=1? (
              <Col span={24}>
                    {ticketList.map((item,index)=>{
                        return (
                          <div class="ticket_card" onClick={showDrawer}>
                            <div class="ticket_card__details">
                              <h3 class="ticket_card__title">{item.ticket_id.name}</h3>
                              <ul>
                                <li>{item.ticket_id.detail}</li>
                              </ul>
                            </div>
                            <div class="ticket_card__rip"></div>
                            <div class="ticket_card__price">
                              <span class="heading">Price ({item.ticket_id.token})</span>
                              <span class="price">{item.ticket_id.amount}</span>
                            </div>
                          </div>
                        )
                    })}
              </Col>
            ) : (
              <Col span={24}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            ))}
          </Row>
        </Card>
      )
    }

    return (
      <>
        <RenderTicket />
        <Drawer
          placement={'bottom'}
          closable={false}
          onClose={onClose}
          height={'80%'}
          open={open}
        >
          <CardScan />
        </Drawer>
      </>


    ); 
};

export default CardTicketComponent;

