import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Checkbox,
  List,
  Spin,
  Avatar,
  Radio,
  Tag,
  Alert,
  Skeleton,
  FloatButton,
  Carousel,
  Result,
  Divider,
  Drawer,
  Dropdown,
  message
} from "antd";

import { CheckOutlined, SendOutlined, GoogleOutlined, ProfileTwoTone, TagsTwoTone, TrophyTwoTone, WalletTwoTone } from "@ant-design/icons";

import { Link } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../../../../contexts/main.jsx";

import LinkAccount from '../../../../components/LinkAccount.jsx'
import CardTicket from '../../../../components/CardTicket.jsx'
import CardTransaction from '../../../../components/CardTransaction.jsx'
import CardNft from '../../../../components/CardNft.jsx'
import CardWallet from '../../../../components/CardWallet.jsx'
import CardScan from '../../../../components/CardScan.jsx'
import CardSend from '../../../../components/CardSend.jsx'


import * as animationData from '../../../../assets/lottie/done.json'

const { Title, Text } = Typography;
const PageLiffKuberWallet = () => {  

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [menuTab, setMenuTab] = useState('transaction');
    const [currency, setCurrency] = useState('KBG');
    const [walletBalance, setWalletBalance] = useState([]);

    const { authLiff, authData, isLogin, addressFormat } = useContext(MainContext);
    const items = [
      {
        key: 'KBG',
        label: 'KBG'
      },
      {
        key: 'KBS',
        label: 'KBS'
      },
      {
        key: 'KBC',
        label: 'KBC'
      }
    ];

    useEffect(() => {  
      if(isLogin==true){
         onLoadBalance()
      }
    }, [isLogin]);  


    const init = async() => {
      await onLoadBalance()
    } 

    const onLoadBalance = async() => {
        setLoading(true)
        const accessToken = await authLiff.getAccessToken()
        fetch("/api/liff/kuber/wallet/list", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            .then((response) => response.json())
            .then((data) => {
                setWalletBalance(data.data)
                setLoading(false)
                console.log('Success:', data);
            })
            .catch((error) => {
                setLoading(false)
                console.error('Error:', error);
            });
    } 

    const showDrawer = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    const onChangeCurrency = (e) => {
      setCurrency(e.key)
      // onLoadBalance(e.key)
    }


    const RenderTransaction = ()=>{
      return <CardTransaction currency={currency} />
    }
    // /<TrophyTwoTone key="nft" twoToneColor={menuTab=="nft"? "#02d667" : "#66666620"} style={{fontSize:25}} onClick={()=>setMenuTab('nft')} />,
              
    const RenderCard = ({item}) => {
      return (
        <div>
          <Card 
            className="wallet_card" style={{backgroundImage: `url(/img/wallet/${item.currency}.png)`}}
            actions={[
              <ProfileTwoTone key="transaction" twoToneColor={menuTab=="transaction"? "#02d667" : "#66666620"} style={{fontSize:25}} onClick={()=>setMenuTab('transaction')} />,
              <TagsTwoTone key="ticket" twoToneColor={menuTab=="ticket"? "#02d667" : "#66666620"} style={{fontSize:25}} onClick={()=>setMenuTab('ticket')} />,
              <WalletTwoTone key="send" twoToneColor={menuTab=="send"? "#02d667" : "#66666620"} style={{fontSize:25}} onClick={()=>setMenuTab('send')} />
            ]}
          >
            <Text>บัญชี</Text><br />
            <div style={{float:'right',marginRight:10}}><Avatar src={`/img/token/${item.currency}.png`} size={50} /></div>
            <Title level={2} className="ma-0 mr-10">{item.balance} <Text>{item.currency}</Text> </Title>
            {authData && authData.wallet && <Tag color="green" className="mt-2"><Text copyable>{addressFormat(authData.wallet[0].address)}</Text></Tag>}
            <br />
            <br />
          </Card>
        </div>
      )
    }
    const onChange = (index) => {
      if(walletBalance[index]){
        setCurrency(walletBalance[index].currency)
      }
    }
                  // <div style={{float:'right',marginRight:10}}>
                  //        <Dropdown menu={{ items, onClick: onChangeCurrency }} placement="bottomRight">
                  //         <Button>{currency}</Button>
                  //       </Dropdown>
                  // </div>    
    return (

        <LinkAccount liffId={'1657440707-57v1Ao19'}>
            <Spin spinning={loading} delay={500}>
            <Row gutter={[0, 0]} className="mb-10">
              <Col span={24}>

                  {walletBalance.length>=1? (<Carousel afterChange={onChange} effect="fade" dotPosition={'top'}>
                    {walletBalance.map(item=>{
                      return <RenderCard item={item}/>
                    })}
                  </Carousel>) :  <Card><Skeleton paragraph={{ rows: 1 }} active={true} /></Card>}
              
              </Col>
              <Col span={24}>
                  {menuTab=="transaction" && (<RenderTransaction  />)}
                  {menuTab=="ticket" && (<CardTicket />)}
                  {/*menuTab=="nft" && (<CardNft />)*/}
                  {menuTab=="send" && (<CardWallet />)}
              </Col>
            </Row>
            <FloatButton type="primary"  icon={<SendOutlined />} onClick={showDrawer} />
            </Spin>
            {open && <Drawer
              placement={'bottom'}
              closable={true}
              title={'Send Token'}
              onClose={onClose}
              height={'80%'}
              open={open}
            >
              <CardSend currency={currency} />
            </Drawer>}
        </LinkAccount>

    ); 
};

export default PageLiffKuberWallet;

