import React, { useCallback, useEffect, useRef, useState, useContext } from "react"; 
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Checkbox,
  List,
  Spin,
  Avatar,
  Radio,
  Alert,
  Result,
  Dropdown,
  Segmented,
  message,
} from "antd";

import { CheckOutlined, UserOutlined, GoogleOutlined, LinkOutlined } from "@ant-design/icons";
import { Fullscreen, useFullscreen } from "react-fullscreen-html";
import './index.css';
import Lottie from 'react-lottie';
import { QRCode } from 'react-qrcode-logo';
import * as animationWait from '../../../assets/lottie/block_confirm.json'
import { MainContext } from "../../../contexts/main.jsx";
const { Title, Text } = Typography;

const Page = () => {
  const screen = useFullscreen();
  const [qr,setQr] = useState(false)
  const [allow,setAllow] = useState(false)
  const [loading,setLoading] = useState(false)
  const [listResult,setListResult] = useState([])
  const [listData,setListData] = useState([])
  const [activity,setActivity] = useState('init')
  const [step,setStep] = useState('init')
  const [showBtn,setShowBtn] = useState('all')
  const [selectReword,setSelectReword] = useState(false)
  const [lucky,setLucky] = useState(false)


  const { sleep } = useContext(MainContext);

  const handleUserKeyPress = useCallback(event => {
      const { key, keyCode } = event;
      const indexActivity = {'1':'init','2':'prompt','3':'end'}
      if(key === '1' || key === '2' || key === '3'){
          setActivity(indexActivity[key]);
          console.log(`Key: ${event.key} with keycode ${event.keyCode} has been pressed`);
      }else{
        if(key === 'f'){
          screen.enter()
          console.log(`Key: ${event.key} with keycode ${event.keyCode} has been setIsFullscreen`);
        }
      }
  }, []);

  useEffect(() => {
      window.addEventListener("keydown", handleUserKeyPress);
      return () => {
          window.removeEventListener("keydown", handleUserKeyPress);
      };
  }, [handleUserKeyPress]);


  const callApi = () => {
    fetch("/api/event/main/init")
      .then((res) => res.json())
      .then((json) => {
        console.log(json)
        setAllow(json.status)
        setLucky(false)
        setSelectReword(false)
        setShowBtn('all')
        setListResult(json.result)
        setListData(json.data)
      })
  }

  useEffect(() => {
    callApi()  
  }, []);

  // 1920*1080

  const actionRandom = async() => {
    setShowBtn('all')
    setStep('loading')
    fetch(`/api/event/main/random/${selectReword.id}`)
      .then((res) => res.json())
      .then(async(json) => {
        console.log(json)
        await sleep(5000)
        setLucky(json.lucky)
        setStep('done')
      })
  }

  const actionConfirm = () =>{
    //load pre data
    setShowBtn('confirm')
    setLoading(true)
    fetch(`/api/event/main/confirm/${selectReword.id}`)
      .then((res) => res.json())
      .then(async(json) => {
        console.log(json)
        await sleep(3000)
        setLoading(false)
        setStep('init')
        callApi()
      })
  }

  const actionCancel = () => {
    setShowBtn('cancel')
    setLoading(true)
    fetch(`/api/event/main/cancel/${selectReword.id}`)
      .then((res) => res.json())
      .then(async(json) => {
        await sleep(3000)
        setLoading(false)
        console.log(json)
        setStep('init')
        callApi()
      })
  }

  const actionSelect = (data) => {
    if(!lucky){
      setStep('prompt')
      setSelectReword(data)
    }
  }

  const actionClear = (data) => {
    setStep('init')
    setSelectReword(false)
  }

  const RanderInit = () =>{

    return (
      <>
        <Space style={{width:'100%'}}>
          <img src={'/img/event/main/logo.png'} style={{width:800}} />
        </Space>
      </>
    )
  }

  const RanderPrompt = () =>{
    var found = selectReword
    return (found && found.id) && (
      <>
        <div className="relative w-full">
          <Avatar size={300} src={`/img/event/main/avatar/${found.id}.png`} shape={found.type=='Gift'? "square" : "circle"} style={{border:'10px solid #ffffff',marginBottom:10}}  />
        </div>
        <Title style={{color:'white',margin:0,padding:0}}>{found.name}</Title>
        <Text style={{color:'white',marginBottom:20,marginTop:10}}>{found.type}</Text>
        <Space>
          <Button size="large" className="match_btn" onClick={actionClear}>Clear</Button>
          <Button size="large" className="match_btn" onClick={actionRandom}>Random</Button>
        </Space>
      </>
    )
  }

  const RanderMatchDone = () =>{
    return (lucky && lucky.id) && (
      <>
        <div className="relative" style={{width:400}}>
          <Avatar size={200} style={{border:'5px solid #ffffff',cursor:'pointer'}} src={`/img/event/main/avatar/${lucky.id}.png`} className="absolute -left-20 top-0" />
          <img alt="" className="h-32" src="/img/event/main/arrow_right.png" style={{margin:'40px auto'}}/>
          <Avatar size={200} style={{border:'5px solid #ffffff',cursor:'pointer'}} src={lucky.done.avatar} className="absolute -right-20 top-0" />
        </div>
        <Title style={{color:'white',margin:0,padding:0}}>{lucky.done.company_email.split('@')[0]}</Title>
        <Space style={{marginTop:20}}>
          {['all','cancel'].includes(showBtn) && <Button size="large" className="match_btn" onClick={actionCancel} loading={loading}>Cancel</Button>}
          {['all','confirm'].includes(showBtn) && <Button size="large" className="match_btn" onClick={actionConfirm} loading={loading}>Confirm</Button>}
        </Space>
      </>
    )
  }

  const RanderLoading = () =>{
    return (
      <div className="relative w-full">
        <Lottie options={{
          animationData: animationWait,
          loop: true,
          autoplay: true,
        }} height={400} width={400}/>
      </div>
    )
  }

  return allow? (
  <Fullscreen handle={screen}>
      <div className="tv-main " >

        <div className={"tv-main-body body_"+activity}>
        {activity!=='init' && (<div className=" backdrop-blur-sm bg-green/80 absolute top-0 left-0 w-full h-full transition-opacity delay-150" />)}
        {activity=='init' && (
          <>
            <img src="/img/event/main/badge_ribbon.png" className="badge_ribbon"/>
            <img src="/img/event/main/badge_line.png" className="badge_line"/>
          </>
        )}
        {activity=='prompt' && (
          <>
            <div className="absolute top-0 left-0 w-full h-full flex justify-between items-center p-10">
                <div className="flex flex-col space-y-3" style={{width:320}}>
                  {listResult.slice(0,5).map(item=>{
                    return (
                      <div style={{marginBottom:20}}>
                        <div className="match_card transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
                          <Avatar.Group style={{marginTop:'-20px'}}>
                            <Avatar size={80} src={`/img/event/main/avatar/${item.id}.png`} shape={item.type=='Gift'? "square" : "circle"}/>
                            <img alt="" className="h-12" src="/img/event/main/arrow_right.png" style={{margin:'20px auto'}}/>
                            <Avatar size={80} src={item.done.avatar} />
                          </Avatar.Group>
                          <Text style={{color:'white',fontSize:18,fontWeight:700,marginTop:5}}>{item.done.company_email.split('@')[0]}</Text>
                        </div>
                      </div>
                    )
                  })}

                </div>

                <div className="flex flex-col items-center" style={{width:650}}>
                  {step=='init' && <RanderInit />}
                  {step=='prompt' && <RanderPrompt />}
                  {step=='done' && <RanderMatchDone />}
                  {step=='loading' && <RanderLoading />}
                </div>
                <div className="flex flex-col items-end space-y-3" style={{width:320}}>
                  <div className="grid grid-cols-3 gap-3">
                    {
                      listData.map(item=>{
                        return (
                          <div onClick={()=>actionSelect(item)}>
                             <Avatar size={90} src={`/img/event/main/avatar/${item.id}.png`} style={{border:'3px solid #ffffff',cursor:'pointer'}}  className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" />
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
            </div>
          </>
        )}
        {activity=='running' && (
          <>
            <div className="grid grid-cols-3 gap-3">
              <Title>running</Title>
            </div>
          </>
        )}
        {activity=='end' && (
          <>
            <div className="tv-main-frame">
              <div className="flex flex-col items-center" style={{marginTop:200}}>
                <div className="grid grid-cols-4 gap-4">
                      {listResult.map(item=>{
                        return (
                          <div style={{marginBottom:20,width:320}}>
                            <div className="match_card transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
                              <Avatar.Group style={{marginTop:'-20px'}}>
                                <Avatar size={80} src={`/img/event/main/avatar/${item.id}.png`} shape={item.type=='Gift'? "square" : "circle"}/>
                                <img alt="" className="h-12" src="/img/event/main/arrow_right.png" style={{margin:'20px auto'}}/>
                                <Avatar size={80} src={item.done.avatar} />
                              </Avatar.Group>
                              <Text style={{color:'white',fontSize:18,fontWeight:700,marginTop:5}}>{item.done.company_email.split('@')[0]}</Text>
                            </div>
                          </div>
                        )
                      })}
                </div>
              </div>
            </div>
          </>
        )}

        <img src="/img/event/main/badge_company.png" className="badge_company_name"/>

        </div>
      </div>
      </Fullscreen>
  ) : (<></>);


}

export default Page;
