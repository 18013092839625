import React, { useEffect, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Empty,
  Typography,
  Space,
  Spin,
  Avatar,
  Tag,
  Alert,
  Result,
  Divider,
  Skeleton,
  Drawer,
  Dropdown,
  message
} from "antd";

import { TrophyOutlined, CheckOutlined, GoogleOutlined } from "@ant-design/icons";

import { Link } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../contexts/main.jsx";

const { Title, Text } = Typography;

const CardNftComponent = ({currency}) => {  

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [nftList, setNftList] = useState([])

    const { authLiff, isLogin } = useContext(MainContext);

    useEffect(() => {  
      if(isLogin==true){
         init();
      }
    }, [isLogin]);    

    const showDrawer = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    const init = async() => {
      await onLoadNft()
    } 

    const onLoadNft = async() => {
        setLoading(true)
        const accessToken = await authLiff.getAccessToken()
        fetch("/api/liff/kuber/list/nft", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            })
            .then((response) => response.json())
            .then((data) => {
                setNftList(data.data)
                setLoading(false)
                console.log('Success:', data);
            })
            .catch((error) => {
                setLoading(false)
                console.error('Error:', error);
            });
    } 

    const onCardAction = () => {

    }

    const RenderNft = () => {
      return (
        <Card className="mb-2">
          <Row gutter={[12, 12]}>
            <Col span={24} align="left">
              <Title level={3}>NFT</Title>
            </Col>
            {loading? (
              <Col span={24}>
                <Skeleton.Node active={true} >
                  <rophyOutlined style={{  fontSize: 40, color: '#bfbfbf' }} />
                </Skeleton.Node>
              </Col>
            ) : (nftList.length >=1? (
              <Col span={24}>
                <Row gutter={[12, 12]}>
                    {nftList.map((item,index)=>{
                        return (
                          <Col span={12} key={'key-'+index}>
                              <Card hoverable style={{ width: '100%',padding:5 }} cover={<img src={item.ipfs_uri+'/'+item.ipfs_image} />} onClick={onCardAction}>
                                <Card.Meta title={`Card no ${item.number}`}/>
                              </Card>
                          </Col>
                        )
                    })}
                </Row>
              </Col>
            ) : (
              <Col span={24}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            ))}
          </Row>
        </Card>
      )
    }

    return (
      <RenderNft />
    ); 
};

export default CardNftComponent;

