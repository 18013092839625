import React, { useEffect, useRef, useState, useContext } from "react"; 
import {
  Row,
  Col,
  Card,
  Button,
  Divider,
  Typography,
  Result,
  Dropdown,
  Image,
  Space,
  QRCode,
} from "antd";
import { Link, useParams } from 'react-router-dom'
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
import './score.css';
const { Title, Text } = Typography;
const numberFormatter = Intl.NumberFormat("en-US");

const Page = () => {

    const [ scoreData, setScoreData ] = useState(false)
    const { authLiff, isLogin, sleep } = useContext(MainContext);

    useEffect(() => {  
      if(isLogin==true){
         onScore()
      }
    }, [isLogin]);  

    const onScore = async() => {
      try {
          const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/event/hodl_day/score`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
                  if(data.status==true){
					setScoreData(Object.keys(data.total).map(item=>{
						return {dao:item,point:data.total[item]}
					}).sort((a, b) => b.point - a.point))
                  }
              }).catch((error) => {});
      } catch(error) {
      }
    }

	const RenderScore = ({data}) => {
	  return (
		<Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
                Score Board
              </Title>
            </Col>
            <Col span={24}>
              <Row gutter={[8, 8]}>
                {data.map((item, index) => (
                  <Col span={24} key={index}>
                    <Card
                      className={"card-leader other"}
                      bodyStyle={{ padding: "4px 16px 4px 4px" }}
                      bordered={false}
                    >
                      <Row justify={"space-between"} align={"middle"}>
                        <Col>
                          <Space size={0}>
                            <Title level={4}>{index + 1}</Title>
                            <Image
                              src={`/img/liff/event/hodl_day/${item.dao}.png`}
                              width={80}
                              preview={false}
                            />
                            <Title level={3} style={{margin:'5px 0 0 0',padding:0}}>{item.dao}</Title>
                          </Space>
                        </Col>
                        <Col>
                          <Space
                            split={<Divider type="vertical" />}
                            size={"small"}
                          >
                            <Space
                              direction="vertical"
                              size={2}
                              style={{ textAlign: "center", width: "60px" }}
                            >
                              <small>Total</small>
                              <Text strong={"true"}>{numberFormatter.format((item.point || 0))}</Text>
                            </Space>
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
	  )
	}

  return (
  	<LinkAccount liffId={'1657440707-zlDxEmxG'}>
	  	<div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/hodl_day/bg.png)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover',padding:20}}>

	            {scoreData && <RenderScore data={scoreData}/>}

	    </div>
	</LinkAccount>
  );
};


export default Page;