import React,{ useRef, useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Drawer,
  InputNumber,
  Checkbox,
  Layout
} from "antd";

import './index.css';
import { QRCode } from 'react-qrcode-logo';
import { CheckOutlined, GoogleOutlined } from "@ant-design/icons";


const { Title, Text } = Typography;

const Page = () => {

  const webcamRef = useRef(null);


  const [qr,setQr] = useState(false)
  const [activity,setActivity] = useState(false)

  const callApiQr = () => {
    setActivity(true)
    console.log('step==>0 : qr call')
    fetch("/api/event/office/qr/ALL:external:1/bitkub_mate")
      .then((res) => res.json())
      .then((json) => {
        setActivity(json.status)
        setQr(json.data)
      })
  }

  useEffect(() => {
      console.log('step==>0 : qr')
      callApiQr()
      const interval = setInterval(() => {
        callApiQr()
      }, 5000);
      return () => clearInterval(interval);
  }, []);



  return (
    <>
      <div className="tv-bitkub_mate">
        <div className="tv-bitkub_mate-body">
          <div className="tv-bitkub_mate-qr">
            <QRCode size={500} value={qr} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
