import React, { useEffect, useRef, useState, useContext } from "react"; 
 import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Empty,
  Divider,
  Typography,
  Select,
  Input,
  Table,
  Space,
  Checkbox,
  List,
  Spin,
  Avatar,
  Radio,
  Alert,
  Result,
  Dropdown,
  QRCode,
  message,
  Segmented
} from "antd";

import { CheckOutlined, GoogleOutlined, LinkOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom'
import Lottie from 'react-lottie';

import { MainContext } from "../contexts/main.jsx";

import * as animationData from '../assets/lottie/done.json'

const { Title, Text } = Typography;

const CardQrComponent = ({type='ALL:auth:0',children,onClear=false}) => {  
    const [ step, setStep ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ qrData, setQrData ] = useState(false)
    const [ qrTime, setQrTime ] = useState(false)
    const { authLiff, sleep } = useContext(MainContext);

    useEffect(() => {  
      if(step==0){
         onRecheck();
      }
    }, [step]);  


    const onRecheck = async() => {
      try {

          const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/qr/gen/${type}`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
                  if(data.status==true){
                    if(data.step==0){
                      setQrData(data.data)
                      setQrTime(data.time)
                      await sleep(5000)
                      onRecheck()
                    }else if(data.step==1){
                      setStep(1)
                      await sleep(1000)
                      onRecheck()
                    }
                  }else{
                    message.info(JSON.stringify(data));
                  }
              })
              .catch((error) => {
              });
      } catch(error) {
      }
    }


    const RenderQr = ({type,onClear}) => {
      var [currency,type,amount] = type.split(':')
      return (
            <Result
              icon={qrData && (<div style={{padding:0,display: 'flex',justifyContent: 'center',alignItems: 'center',textAlign: 'center'}}>
                <QRCode
                  errorLevel="H"
                  size={250}
                  value={qrData}
                  icon={((amount && currency) && currency!='ALL')? `/img/token/${currency}.png` : "/k-logo.png"}
                />
              </div>)}
              title={'Scan QR'}
              subTitle="กรุณาแสดง QR เพื่อทำรายการ"
              style={{padding:0}}
            />
      )
    }

    const RenderSuccess = () => {
        return (
            <Result
              icon={<Lottie options={{
                  animationData: animationData,
                  loop: false,
                  autoplay: true,
                }} height={200} width={200}/>}
              title="SUCCESS"
              subTitle="ทำการโอนสำเร็จ"
              style={{padding:0}}
              extra={[
                <Button type="primary" block size="large" onClick={()=>{
                    authLiff.closeWindow();
                }}>
                  Close
                </Button>
              ]}
            />
        )
    }


    return (
        <>
              {step==0 && <RenderQr type={type} onClear={onClear}/>}
              {step==1 && <RenderSuccess/>}
        </>
    ); 
};

export default CardQrComponent;

