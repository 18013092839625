import React, { useEffect, useRef, useState, useContext } from "react"; 
import {
  Row,
  Col,
  Card,
  Button,
  Divider,
  Typography,
  Result,
  Dropdown,
  Segmented,
  Avatar,
  Image,
  Table,
  Space,
  message,
  Form,
  QRCode,
} from "antd";
import {
  ProForm,
  ProFormText,
  DrawerForm,
  ProFormSelect
} from '@ant-design/pro-components';
import { Link, useParams } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
import * as animationData from '../../../../../assets/lottie/done.json'
import * as animationScand from '../../../../../assets/lottie/scand.json'
import './score.css';
const { Title, Text, Paragraph } = Typography;
const numberFormatter = Intl.NumberFormat("en-US");

const Page = () => {
    const [form] = Form.useForm();
    const [modalVisit, setModalVisit] = useState(false);
    const [modalData, setModalData] = useState(false);
    const [modalSelect, setModalSelect] = useState(false);
    const [ waiting, setWaiting ] = useState(false);
    const [ scoreData, setScoreData ] = useState(false)
    const [ activityForm, setActivityForm ] = useState(false)
    const [ historyData, setHistoryData ] = useState(false)
    const [ historyIndex, setHistoryIndex] = useState({})
    const [ step, setStep ] = useState(0)
    const [ dataScan, setDataScan] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ menuTab, setMenuTab] = useState('Score')
    const { authLiff, isLogin, sleep } = useContext(MainContext);

    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (_, record) => (
          <Space>
            <Avatar src={record.avatar} alt="" />
            <Text>{record.email?.split('@')[0] || '-'} ({record.dao})</Text>
          </Space>
        ),
      },
      
      {
        title: 'Date Time',
        dataIndex: 'date_rec',
        key: 'date_rec',
      },
    ];

    const columnsActivity = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (_, record) => (
          <Text strong onClick={()=>{
            
            setModalSelect(record)
            setModalData({
              "title":record.title,
              "data_BULLISH" : record.data.BULLISH,
              "data_BEARISH" : record.data.BEARISH,
              "data_MOON" : record.data.MOON,
              "data_LAMBO" : record.data.LAMBO
            })
            setModalVisit(true)
          }}>{record.title}</Text>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'date_event',
        key: 'date_event',
      }
    ]


    useEffect(() => {  
      if(isLogin==true){
         onfetch()
      }
    }, [isLogin]);  


    const onfetch = async() => {
       await onHistory()
       await onScore()
    }

    const onScore = async() => {
      try {
          const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/event/hodl_day/score`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
                  if(data.status==true){
          					setScoreData(Object.keys(data.total).map(item=>{
          						return {dao:item,point:data.total[item],member:historyIndex[item] || 0}
          					}).sort((a, b) => b.point - a.point))
                    setActivityForm(data.data)
                  }else{
                    setScoreData([])
                  }
              }).catch((error) => {
                setScoreData([])
              });
      } catch(error) {
        setScoreData([])
      }
    }

    const onHistory = async() => {
      try {
          const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/event/hodl_day/qr/history`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
                  setHistoryData(data.data)
                  if(data.status==true){
                    var index = {}
                    
                    data.data.filter(item=>item?.dao || false).map(item=>{
                      index[item.dao.toUpperCase()] = (index[item.dao?.toUpperCase()] || 0) + 1
                    }).sort((a, b) => parseFloat(b.timestamp) - parseFloat(a.timestamp))
                    setHistoryIndex(index)
                  }
              }).catch((error) => {
                setHistoryData([{error:0}])
              });
      } catch(error) {
        setHistoryData([{error:1}])
      }
    }

	const RenderScore = () => {
	  return scoreData && (
		<Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
                Score Board
              </Title>
            </Col>
            <Col span={24}>
              <Row gutter={[8, 8]}>
                {scoreData && scoreData?.map((item, index) => (
                  <Col span={24} key={index}>
                    <Card
                      className={"card-leader other"}
                      bodyStyle={{ padding: "4px 16px 4px 4px" }}
                      bordered={false}
                    >
                      <Row justify={"space-between"} align={"middle"}>
                        <Col>
                          <Space size={0}>
                            <Title level={4}>{index + 1}</Title>
                            <Image
                              src={`/img/liff/event/hodl_day/${item.dao}.png`}
                              width={80}
                              preview={false}
                            />
                          </Space>
                        </Col>
                        <Col>
                          <Space
                            split={<Divider type="vertical" />}
                            size={"small"}
                          >
                            <Space
                              direction="vertical"
                              size={2}
                              style={{ textAlign: "center", width: "60px" }}
                            >
                              <small>Join</small>
                              <Text strong={"true"}>{numberFormatter.format((item.member || 0))}</Text>
                            </Space>
                            <Space
                              direction="vertical"
                              size={2}
                              style={{ textAlign: "center", width: "60px" }}
                            >
                              <small>Total</small>
                              <Text strong={"true"}>{numberFormatter.format((item.point || 0))}</Text>
                            </Space>
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
	  )
	}


  const queryCode = async(value) => {
    const accessToken = await authLiff.getAccessToken()
    fetch('/api/liff/kuber/event/hodl_day/qr/scan', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
              code:value,
              sv:'none'
            }),
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status==true){
                setDataScan(data.info)
                setStep(1)
            }else{
              message.info(data.message);
            }
        })
        .catch((error) => {
            message.info('line not support');
        });
  }

  const onScanAction = async() => {
    try {
        setLoading(true)
        authLiff
          .scanCodeV2()
          .then(async(result) => {
                if(result && result.value){
                  await queryCode(result.value)
                  setLoading(false)
                }else{
                  setLoading(false)
                }
          }).catch((error) => {
              setLoading(false)
              message.info('line app not support camera');
          });
      
    } catch(error) {
      setLoading(false)
    }
  }

  const RenderScan = () =>{
    return (    
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
            Scan Qr
          </Title>
        </Col>
        <Col span={24}>
        {step==0? (
          <Card size={'small'}>
            <Result
              icon={<Lottie options={{
                  animationData: animationScand,
                  loop: false,
                  autoplay: true,
                }} height={200} width={200}/>}
              title={'Scan QR'}
              subTitle={"กรุณาทำการกดที่ปุ่ม Scan Qr"}
              style={{padding:0}}
              extra={[
                <Button type="primary" block size="large" onClick={onScanAction} loading={loading} disabled={loading}>
                  Scan Qr
                </Button>
              ]}
            />
          </Card>
        ) :  (
          <Card size={'small'}>
            {dataScan && (<Result
              title={'Register success'}
              subTitle={'ทำการลงทะเบียนเข้างานสำเร็จ'}
              icon={
                <div style={{position:'relative',width:100,margin:'30px auto 0'}}>
                  <Avatar size={100} src={dataScan?.avatar} />
                  <Avatar size={50} src={`/img/token/${dataScan?.dao}.png`} style={{position:'absolute',bottom:-10,right:-10}} />
                </div>
              }
              extra={[
                <Button type="primary" block size="large" onClick={()=>{
                    setStep(0);
                    setDataScan(false);
                }}>
                  Close
                </Button>
              ]}
              style={{padding:0}}
            >
              {dataScan && <div className="desc" style={{padding:0}}>
                <Paragraph>
                  <Text
                    strong
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Information:
                  </Text>
                </Paragraph>
                <Paragraph>
                  {dataScan?.name && (<><Text>Name : <Text copyable={{ text: dataScan?.name }}>{dataScan?.name}</Text></Text><br /></>)}
                  {dataScan?.employee_id && (<><Text>Employee ID : <Text copyable={{ text: dataScan?.employee_id }}>{dataScan?.employee_id}</Text></Text><br /></>)}
                  {dataScan?.dao && (<><Text>DAO : <Text copyable={{ text: dataScan?.dao }}>{dataScan?.dao}</Text></Text><br /></>)}
                </Paragraph>
              </div>}
            </Result>)}
          </Card>
        )}
        </Col>
      </Row>
    )
  }


  // /api/liff/:key/event/hodl_day/qr/history
  const RenderHistory = () =>{
    return (<div>
      <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
                  History (Total {historyData.length})
                </Title>
              </Col>
              <Col span={24}>
                <Card size={'small'}>
                  {historyData && <Table dataSource={historyData} columns={columns} />}
                </Card>
              </Col>
      </Row>
    </div>)
  }
  const RenderActivity = () =>{
    return (<div>
      <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
                  Form Activity Score
                </Title>
              </Col>
              <Col span={24}>
                <Card size={'small'}>
                  <Table dataSource={activityForm} columns={columnsActivity} />
                </Card>
              </Col>
      </Row>
    </div>)
  }
  // <LinkAccount liffId={'1657440707-QVADWMDk'}>
  return (
    <LinkAccount liffId={'1657440707-QVADWMDk'}>
	  	<div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/hodl_day/bg.png)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover',padding:20}}>
            <Row gutter={[0, 0]} className="mb-10">
              <Col span={24}>
              <Segmented
                defaultValue="center"
                style={{ marginBottom: 8 }}
                onChange={async(value) => {
                  onfetch()
                  setMenuTab(value)
                }}
                options={['Score', 'Scan', 'History', 'Activity']}
              />
              </Col>
              <Col span={24}>
                  {menuTab=="Score" && (<RenderScore />)}
                  {menuTab=="Scan" && (<RenderScan  />)}
                  {menuTab=="History" && (<RenderHistory />)}
                  {menuTab=="Activity" && (<RenderActivity />)}
              </Col>
            </Row>
          {(modalVisit && modalSelect) && (<DrawerForm
            title={modalSelect.title}
            open={modalVisit}
            onOpenChange={setModalVisit}
            initialValues={modalData}
            autoFocusFirstInput
            drawerProps={{
              destroyOnClose: true,
            }}
            loading={waiting}
            onFinish={async (values) => {
              console.log(values);

              setWaiting(true)
              const accessToken = await authLiff.getAccessToken()
              return fetch(`/api/liff/kuber/event/hodl_day/form/${modalSelect._id.toString()}`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                      },
                      body: JSON.stringify(values),
                  })
                  .then((response) => response.json())
                  .then(async(data) => {
                      setWaiting(false)
                      if(data.status==true){
                        message.success(data.msg);
                      }else{
                        message.info(data.msg);
                      }
                      await onfetch()
                      return true;
                  })
                  .catch((error) => {
                      message.info('error');
                      setWaiting(false)
                      return true;
                  });

            }}
          >
            <ProForm.Group>
              <ProFormText
                name="title"
                rules={[
                  {
                    required: true,
                  },
                ]}
                width="md"
                label="Title"
                placeholder="title"
              />
            </ProForm.Group>
            <ProForm.Group>
              <ProFormSelect
                width="xs"
                options={modalSelect.choice.map(val=>{
                  return {
                    value: val,
                    label: val,
                  }
                })}
                name="data_BULLISH"
                label="BULLISH"
              /><ProFormSelect
                width="xs"
                options={modalSelect.choice.map(val=>{
                  return {
                    value: val,
                    label: val,
                  }
                })}
                name="data_BEARISH"
                label="BEARISH"
              /><ProFormSelect
                width="xs"
                options={modalSelect.choice.map(val=>{
                  return {
                    value: val,
                    label: val,
                  }
                })}
                name="data_MOON"
                label="MOON"
              /><ProFormSelect
                width="xs"
                options={modalSelect.choice.map(val=>{
                  return {
                    value: val,
                    label: val,
                  }
                })}
                name="data_LAMBO"
                label="LAMBO"
              />
            </ProForm.Group>
          </DrawerForm>)}


	    </div>
    </LinkAccount>
  );
};


export default Page;