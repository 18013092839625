import React, { useState, useEffect } from 'react';

import './index.css';
import { QRCode } from 'react-qrcode-logo';

const Page = () => {
  const [qr,setQr] = useState(false)
  const [activity,setActivity] = useState(false)

  const callApi = () => {
    fetch("/api/event/checkin/lookup")
      .then((res) => res.json())
      .then((json) => {
        setActivity(json.status)
        setQr(json.res)
      })
  }

  useEffect(() => {
    callApi()
    const interval = setInterval(() => {
      callApi()
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
      <div className="tv-checkin">
        <div className="tv-checkin-body">
        {activity && (<>
      
        <div style={{height: 130}}></div>
        <div className="tv-checkin-qr">
          {qr && <QRCode size={340} value={qr}/>}
        </div>
        </>)}
        </div>
      </div>
  );


}

export default Page;
