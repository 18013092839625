import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  Divider,
  Radio,
  Modal
} from "antd";
import { CloseOutlined, ZoomOutOutlined, ZoomInOutlined } from '@ant-design/icons';
import { MiniMap, TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import { Link, useParams } from 'react-router-dom'
import './map.css';
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
const Page = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	// '1657440707-VwQOjJON'
	
	// /img/liff/event/hodl_day/1.png
	// /img/liff/event/hodl_day/1_img.png
	const data_event = {
		'1':{
			node:1,
			detail:[
				`<p>กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นทีละสี สีละ 4 คน</li>
				<li>2. มีเวลา 1 นาทีต่อเกม ทำแต้มให้ได้มากที่สุด!</li>
				<li>3. กรุณาอย่าปีนเข้าไปในพื้นที่ของแป้นห่วง</li>
				<li>4. หลังจากจบเกมของสีตัวเองแล้ว กรุณาเปลี่ยนรอบให้สีถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : คะแนน</span>
				</p>`
			]
		},
		'2':{
			node:2,
			detail:[
				`<p>
				กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นพร้อมกัน 2 สี สีละ 1 คน แข่งขันแบบ VS เพื่อหาผู้ชนะ-แพ้</li>
				<li>2. มีเวลา 1 นาทีต่อเกม ทำคะแนนให้ได้มากที่สุด</li>
				<li>3. ห้ามผลักหรือชนผู้เล่นคนอื่น</li>
				<li>4. หลังจากจบเกมแล้ว กรุณาเปลี่ยนรอบให้ผู้แข่งรอบถัดไปเข้าเล่น</li>
				</ul>
				<br />
				<span style="text-align:left">เกณฑ์การตัดสิน : คะแนน</span>
				</p>`
			]
		},
		'3':{
			node:3,
			detail:[
				`<p>
				กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นพร้อมกัน 4 สี สีละ 1 คน</li>
				<li>2. มีเวลา 1 นาทีต่อเกม ทำเวลาได้ให้ดีที่สุด</li>
				<li>3. หลังจากจบเกมแล้ว กรุณาเปลี่ยนรอบให้ผู้แข่งรอบถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : เวลา</span>
				</p>`
			]
		},
		'4':{
			node:4,
			detail:[
				`<p>
				กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นพร้อมกัน 4 สี สีละ 1 คน</li>
				<li>2. มีเวลา 1 นาทีต่อเกม ทำเวลาได้ให้ดีที่สุด</li>
				<li>3. หลังจากจบเกมแล้ว กรุณาเปลี่ยนรอบให้ผู้แข่งรอบถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : คะแนน</span>
				</p>`
			]
		},
		'5':{
			node:5,
			detail:[
				`<p>กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นทีละสี สีละ 4 คน</li>
				<li>2. เวลาเริ่มนับจากที่คันแรกออกจากจุดเริ่มต้น และหยุดเวลา เมื่อคันสุดท้ายมาถึงเส้นชัย ทำเวลาให้ได้น้อยที่สุด!</li>
				<li>3. ใช้รถคันใหญ่ 2 คัน และคันเล็ก 2 คัน</li>
				<li>4. ขนาดรถและผู้เล่นต้องเหมาะสมกัน</li>
				<li>4. ห้ามเริ่มปั่น ก่อนที่ Staff จะส่งสัญญาณเริ่ม</li>
				<li>5. ห้ามชนผู้เล่นคนอื่น</li>
				<li>6. หลังจากจบเกมของสีตัวเองแล้ว กรุณาเปลี่ยนรอบให้สีถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : เวลา</span>
				</p>`
			]
		},
		'6':{
			node:6,
			detail:[
				`<p>กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นทีละ 2 สี สีละ 1 คน แข่งขันแบบ VS เพื่อหาผู้ชนะ-แพ้</li>
				<li>2. ผู้เข้าแข่งขันจะต้องมีทักษะ และความชำนาญในการปีนผา</li>
				<li>3. ผู้เข้าแข่งขันจะต้องปีนขึ้นไปหยิบธง Bitkub HODL Day Season 4 สีใดที่หยิบธงได้ก่อนจะเป็นผู้ชนะ</li>
				<li>4. ห้ามผลักหรือชนผู้เล่นคนอื่น</li>
				<li>5. ผู้เล่นจะต้องสวมรองเท้า และอุปกรณ์รักษาความปลอดภัยขณะเล่นทุกครั้ง</li>
				<li>6. หลังจากจบเกมของสีตัวเองแล้ว กรุณาเปลี่ยนรอบให้สีถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : สีใดที่หยิบธงได้ก่อนจะเป็นผู้ชนะ</span>
				</p>`
			]
		},
		'7':{
			node:7,
			detail:[
				`<p>กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นทีละสี สีละ 4 คน</li>
				<li>2. มีเวลา 1 นาทีต่อเกม ทำแต้มให้ได้มากที่สุด!</li>
				<li>3. ห้ามยืนหรือปีนใต้ผู้เล่นคนอื่น</li>
				<li>4. หลังจากจบเกมของสีตัวเองแล้ว กรุณาเปลี่ยนรอบให้สีถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : คะแนน</span>
				</p>`
			]
		},
		'8':{
			node:8,
			detail:[
				`<p>กรุณาอ่านกติกาให้ครบถ้วนก่อนเริ่มการแข่งขัน<br /><br />
				<ul style="text-align:left">
				<li>1. เล่นทีละสี สีละ 4 คน</li>
				<li>2. เตะลูกบอลไปที่เป้าหมาย ห้ามเตะหรือโยนลูกบอลใส่ผู้เล่นคนอื่น</li>
				<li>3. ห้ามเดินข้ามเส้นเข้าไปในพื้นที่เกม</li>
				<li>4. หลังจากจบเกมของสีตัวเองแล้ว กรุณาเปลี่ยนรอบให้สีถัดไปเข้าเล่น</li>
				</ul><br />
				<span style="text-align:left">เกณฑ์การตัดสิน : คะแนน</span>
				</p>`
			]
		}
	}
  const showModal = (id) => {
    setIsModalOpen(id);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const RenderPin = ({left=0,top=0,id}) =>{
  	// backgroundColor:'#00000050'
  	return (
  		<div style={{width:50,height:80,top,left,position:'absolute',cursor:'pointer'}} onClick={()=>showModal(id)}></div>
  	)
  }

	const Controls = () => {
	  const { zoomIn, zoomOut, resetTransform } = useControls();

	  return (
	    <div style={{
			  position: "fixed",
			  zIndex: 5,
			  left: "20px",
			  top: "20px",
			}}>
				<Button.Group>
	        <Button onClick={() => zoomIn()} icon={<ZoomInOutlined />}/>
	        <Button onClick={() => zoomOut()} icon={<ZoomOutOutlined />}/>
	        <Button onClick={() => resetTransform()} icon={<CloseOutlined/>}/>
	      </Button.Group>
	    </div>
	  );
	};

  const element = (
	<div
	  style={{
	    background : 'url(/img/liff/event/hodl_day/map.png)',
	    color: "white",
	    width: 1600,
	    height: 1133
	  }}
	>
	  <RenderPin left={832} top={454} id='1'/>
	  <RenderPin left={900} top={430} id='2'/>
	  <RenderPin left={960} top={430} id='3'/>
	  <RenderPin left={1035} top={325} id='4'/>
	  <RenderPin left={1108} top={375} id='5'/>
	  <RenderPin left={1292} top={620} id='6'/>
	  <RenderPin left={1386} top={660} id='7'/>
	  <RenderPin left={1063} top={610} id='8'/>
	</div>

  )

  return (

	  	<div style={{height:'100vh',backgroundColor:'#000000'}}>
		    <TransformWrapper
		    initialPositionX={-590}
	      >
	      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
	        <>
							<div
								style={{
								  position: "fixed",
								  zIndex: 5,
								  right: "20px",
								  bottom: "20px",
								}}
							>
								<MiniMap width={200}>{element}</MiniMap>
							</div>
		   				<Controls />
		          <TransformComponent
		            wrapperStyle={{
		              maxWidth: "100%",
		              maxHeight: "100vh",
		            }}
		          >
		            {element}
		          </TransformComponent>
	       </>
	      )}
		    </TransformWrapper>

		    {isModalOpen && (<Modal closable={true} destroyOnClose={true} title={(<img src={"/img/liff/event/hodl_day/"+isModalOpen+".png"} style={{margin:'auto'}}/>)} 
		    	open={isModalOpen} 
		    	onCancel={handleCancel}
					footer={(_, { CancelBtn }) => (
					  <></>
					)}
		    	>
			  	<Row gutter={[0, 0]}>
						<Col span={24}>
						  <div style={{padding:40,textAlign:'center'}}>
						  	<img src={"/img/liff/event/hodl_day/"+isModalOpen+"_img.png"} style={{width:'80%',margin:'auto'}}/>
						  	<br />
						  	<div dangerouslySetInnerHTML={{__html:data_event[isModalOpen].detail}}></div>
							</div>
						</Col>
					</Row>
      </Modal>)}
	    </div>

  );
};


export default Page;